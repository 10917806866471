import React, { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import YouTube from 'react-youtube';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import videos from '../../resources/youtubevideo.json'; // Ensure this JSON contains an array of YouTube URLs

const Youtubeslider = () => {
    const [index, setIndex] = useState(0);
    const [chunkSize, setChunkSize] = useState(4);
    const [videoDetails, setVideoDetails] = useState([]);
    const [language, setLanguage] = useState('en');

    // Media queries
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const isMediumScreen = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1199px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 991px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    // Determine chunk size based on screen size
    useEffect(() => {
        if (isLargeScreen) setChunkSize(3);
        else if (isMediumScreen) setChunkSize(3);
        else if (isTablet) setChunkSize(2);
        else if (isMobile) setChunkSize(1);
    }, [isLargeScreen, isMediumScreen, isTablet, isMobile]);

    // Extract video IDs from links
    const videoIds = videos[language].map(link => link.split('v=')[1]);

    // YouTube Data API key (replace with your own key)
    const API_KEY = 'AIzaSyDwff-rQZOjlXg2ISmeqnZ2VKrisZwQIbs'; // Replace with your API key

    // Fetch video details from YouTube API
    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const details = [];
                for (const videoId of videoIds) {
                    const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`;
                    const response = await axios.get(url);
                    const snippet = response.data.items[0].snippet;
                    details.push({
                        id: videoId,
                        title: snippet.title,
                        description: snippet.description,
                    });
                }
                setVideoDetails(details);
            } catch (error) {
                console.error('Error fetching video details:', error);
            }
        };

        fetchVideoDetails();
    }, [videoIds]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    // Split the videos array into chunks of the determined size
    const videoChunks = [];
    for (let i = 0; i < videoDetails.length; i += chunkSize) {
        videoChunks.push(videoDetails.slice(i, i + chunkSize));
    }

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <DropdownButton id="dropdown-basic-button" title={`Language: ${language === 'en' ? 'English' : 'Bengali'}`}>
                        <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLanguageChange('ben')}>Bengali</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        prevIcon={<span className="carousel-control-prev-icon" />}
                        nextIcon={<span className="carousel-control-next-icon" />}
                        className="custom-carousel">
                        {videoChunks.map((videoChunk, idx) => (
                            <Carousel.Item key={idx}>
                                <Row>
                                    {videoChunk.map((video, vidIdx) => (
                                        <Col xs={12} sm={6} md={4} lg={3} key={vidIdx}>
                                            <YouTube
                                                videoId={video.id}
                                                opts={{
                                                    width: '100%',
                                                    playerVars: {
                                                        autoplay: 0,
                                                    },
                                                }}
                                            />
                                            <div className="text-center mt-2">
                                                <h5 style={{ color: 'rgb(109, 169, 228)' }}>{video.title}</h5>
                                                {/* <p style={{ color: 'black' }}>{video.description}</p> */}
                                                <br></br>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default Youtubeslider;
