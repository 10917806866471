import React, { useState, useRef, useEffect } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import headImage from "../Image/header_cgc.jpg";
import footerImage from "../Image/footer_cgc.jpg";
const NoticePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [visibleItems, setVisibleItems] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [filteredItems, setfilteredItems] = useState();

  const [noticeData, setNoticeData] = useState([]);

  useEffect(() => {
    // Fetch notice data from the endpoint
    const fetchNotices = async () => {
      try {
        const response = await fetch("https://cgcbackend.onrender.com/notices");
        const data = await response.json();
        const reversedData = data.map(notice => ({
          ...notice,
          items: notice.items.slice().reverse()
        }));
        setNoticeData(reversedData);
      } catch (error) {
        console.error("Failed to fetch notices:", error);
      }
    };

    fetchNotices();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setVisibleItems(10);
  };

  const handleShowMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
  };

  const filteredData = noticeData.filter(
    (tab) => tab.title.toLowerCase() !== "undefined" || null
  );

  const getCurrentTabData = () => {
    const currentTabItems = filteredData[activeTab]?.items;
    return currentTabItems?.slice(0, visibleItems);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filterByDateRange = (items) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return items.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    } else if (
      searchText !== "undefined" &&
      searchText !== null &&
      searchText !== ""
    ) {
      return items?.filter((item) => {
        return item.title.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return items;
  };

  useEffect(() => {
    const currentTabData = getCurrentTabData();
    setfilteredItems(filterByDateRange(currentTabData));
  }, [filteredData, visibleItems, activeTab]);

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
  };

  const closePopup = () => {
    setSelectedNotice(null);
  };

  const pdfExportComponent = useRef(null);

  return (
    <div>
      <div className="filters">
        <input
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search by title"
        />
        <input type="date" value={startDate} onChange={handleStartDateChange} />
        <input type="date" value={endDate} onChange={handleEndDateChange} />
      </div>
      <div className="noticetabs">
        {filteredData.map((tab, index) => (
          <button
            key={tab.id}
            className={`noticetabs ${index === activeTab ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="notice-tab-content">
        {filteredItems?.map((item) => (
          <div
            key={item.id}
            className="item"
            onClick={() => handleNoticeClick(item)}
          >
            <h3>{item.title}</h3>
            <p>
              {item.date.includes(",")
                ? item.date
                : new Date(item.date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
            </p>
          </div>
        ))}
        {filteredData[activeTab]?.items.length > visibleItems && (
          <button className="show-more" onClick={handleShowMore}>
            Show More
          </button>
        )}
      </div>
      {selectedNotice && (
        <div
          className="popup"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <PDFExport
            ref={pdfExportComponent}
            paperSize="A4"
            fileName={`${selectedNotice.title}_${selectedNotice.date}`}
          >
            <div className="popup-content">
              <div className="header">
                <img src={headImage} alt="" width="100%" height="100%" />
              </div>
              {/* <h3>{selectedNotice.title}</h3> */}
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    "<p style='text-align: left;'>" +
                    selectedNotice.description.replace(/\n/g, "<br>") +
                    "</p>",
                }}
              ></p>
              <p>
                Dated:{" "}
                {new Date(selectedNotice.date).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>
              {selectedNotice.attachment && (
                <button
                  className="download-button"
                  onClick={() =>
                    window.open(selectedNotice.attachment, "_blank")
                  }
                >
                  Download Attachment
                </button>
              )}
              <div className="footer">
                <img src={footerImage} alt="" width="100%" height="100%" />
              </div>
            </div>
          </PDFExport>
          <div className="button-container">
            <button
              className="download-button"
              onClick={() => pdfExportComponent.current.save()}
            >
              Download PDF
            </button>
            <button className="close-button" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoticePage;
