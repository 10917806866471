import React from 'react'
import Table from "../components/table"
import data from '../resources/syllabus.json'

const Syllabus = ({dept}) => {
    const filteredData = data[dept];
  return (
    <div>
    {filteredData  && <Table data={filteredData} text={"Downlaod"}/>}
    
    
    </div>

  )
}

export default Syllabus