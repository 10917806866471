import React from 'react';


const data = [
 
  { id: 1, date: "2018-19", heading: 'Action Taken', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/ACTION TAKEN REPORT OF IQAC 2018-19.pdf' },
  { id: 2, date: "2019-20", heading: 'Action Taken', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/ACTION TAKEN REPORT OF IQAC 2019-20.pdf' },
  { id: 3, date: "2020-21", heading: 'Action Taken', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/ACTION TAKEN REPORT OF IQAC 2020-21.pdf' },
  { id: 4, date: "2021-22", heading: 'Action Taken', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/ACTION TAKEN REPORT OF IQAC 2021-22.pdf' },
];


const ActionTaken = () => {
  return (
    <div className="container">
      {data.map((item) => (
        <div key={item.id} className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <p>Year : {item.date}</p>
              </div>
              <div className="col-md-6">
                <h3>{item.heading}</h3>
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.profileurl;
                  }}>Download</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActionTaken;
