import React from 'react';

const Table = ({ data, text }) => {
  const tableHeaders = Object.keys(data[0]).filter(header => header !== '_id');

  const isURL = (value) => {
    try {
      const url = new URL(value);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (error) {
      return false;
    }
  };

  const isImageURL = (value) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    const extension = value.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  const createMarkup = (value) => {
    return { __html: value };
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {tableHeaders?.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              {tableHeaders.map((header) => (
                <td key={header} className={isURL(item[header]) ? 'link-cell' : 'text-cell'}>
                  {isURL(item[header]) ? (
                    isImageURL(item[header]) ? (
                      <a href={item[header]} target="_blank" rel="noopener noreferrer">
                        <img src={item[header]} alt="Thumbnail" width="200" />
                      </a>
                    ) : (
                      <a href={item[header]} target="_blank" rel="noopener noreferrer">
                        <span dangerouslySetInnerHTML={createMarkup(text)} />
                      </a>
                    )
                  ) : (
                    <span dangerouslySetInnerHTML={createMarkup(item[header])} />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
