import React from 'react';
import data from '../resources/nirf.json'
import Table from '../components/table';

const Nirf = () => {  
    return (
      <div className="App">
        <h1 style={{
                borderBottom: '2px solid rgb(109, 169, 228)',
                display: 'inline-block',
                marginTop: '45px'
            }}>NIRF</h1>
        <Table data={data} text={'View'}/>
      </div>
    );
  };
  
  export default Nirf;