import React from 'react'
import CarouselComponent from '../components/CarouselComponent'
import TableComponent from '../components/TableComponent'
import image1 from '../Image/staff1.jpg'


const Staff = () => {
        const staffDetail = [
            {  name: 'Mr.Partha Saha ', designation: 'Head clerk ' },
            {  name: 'Mr. Sikandar Rajak ', designation: 'Cashier ' },
            {  name: 'Mr. Avinash Yadav ', designation: 'Clerk ' },
            {  name: 'Mr. Monu Singh ', designation: 'Office Assistant' },
            {  name: 'Miss. Jayita Singh  ', designation: 'Typist' },
            {  name: 'Mr. Pankaj Thakur', designation: 'Karmabandhu' },
            {  name: 'Mrs. Madhumita Majhi', designation: 'Guard ' },
            {  name: 'Mr. Sonu  Singh', designation: 'Night Guard ' },
            {  name: 'Mr. Shova Nayak ', designation: 'Sweeper ' },
            {  name: 'Kalidasi Hazra ', designation: ' 	Sweeper ' },
            // Add more data here...
          ];
          const staffImage=[image1];

  return (
    <>
    <div><CarouselComponent data={staffImage} /></div><br/>
    <div><TableComponent tabledata={staffDetail} /></div>
    </>

  )
}

export default Staff;