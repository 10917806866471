import React from 'react';
import data from './subjects.json';
import Merit from '../../Pages/Merit';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SubjectComponent = () => {

  const openRules = (url) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <div className="container">
        <h3 className="text-center mt-4"> Programmes Offered For Semester Wise 3 Year B.A./B.Sc.(Multidisciplinary) Degree Programme
</h3>
        <div className="table-responsive">
          <table className="table table-bordered mt-4" style={{ backgroundColor: '#fff' }}>
            <thead className="thead-light">
              <tr>
                <th>Program</th>
                <th>Subjects</th>
                <th>Duration</th>
                <th>Regulation</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  <td>{row.program}</td>
                  <td>
                    {row.subjects.map((subject, index) => (
                      <div key={index}>{subject}</div>
                    ))}
                  </td>
                  <td>{row.duration}</td>
                  <td><Button onClick={() => openRules(row.regulations)}>
                  Admission Regulations
                  </Button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <h2>Merit Calculation Formula</h2>
        <Merit />
    </>
  );
};

export default SubjectComponent;
