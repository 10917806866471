import React from 'react'
import Cells from '../components/Cells'
import data from '../resources/Cells.json'


const MinorityCell = () => {
  return (
    <>
        <div><Cells data={data} itemname={"Minority Cell"}/></div>
    
  
    </>
  )
}

export default MinorityCell
