import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const setIsAuthenticated = useStoreActions((actions) => actions.user.setIsAuthenticated);
  const setUsernameInStore = useStoreActions((actions) => actions.user.setUsername);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://cgcbackend.onrender.com/users/validate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // Successful login
        setError("");
        setIsAuthenticated(true); // Update the isAuthenticated state to true
        setUsernameInStore(username); // Store the username in the store
        navigate("/dashboard"); // Redirect to the dashboard
      } else {
        // Invalid credentials
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Failed to validate user:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <img
        src="https://static.muoninfotech.com/./assets/resources/pic.png"
        alt="Logo"
        style={{ width: "100px", marginBottom: "20px" }}
      />
      <h2 style={{ color: "#333" }}>Login</h2>
      <form
        onSubmit={handleLoginSubmit}
        style={{ maxWidth: "300px", margin: "0 auto" }}
      >
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "2px solid #333",
            borderRadius: "5px",
          }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            border: "2px solid #333",
            borderRadius: "5px",
          }}
        />
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#333",
            color: "#fff",
            borderRadius: "5px",
            animation: "pulse 1s infinite",
          }}
        >
          Login
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default LoginPage;
