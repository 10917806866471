import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';


const CardComponent = ({ cardData }) => {
  //console.log(cardData);

  const openProfile = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div className="card-container-teacher">
      {cardData.map((card) => (
        <div key={card.id} className="card-teacher">
          <span className="card-join-badge">Joined on: {card.joinDate}</span>
          <img
            src={card.image}
            alt={card.name}
            className="card-image"
            width="200"
            height="200"
          />
          <br />
          <div className="card-info">
            <Row>
              <Col>
                <h5 className="card-name">{card.name}</h5>
                <span className="card-designation">{card.designation}</span> |
                <span className="card-designation"> {card.qualification}</span>
                <span>
                  <p className="card-designation">
                    <b>Specialisation</b> :{card.specialisation}
                  </p>
                </span>
                <span>
                  <p className="card-email">
                    <b>Connect at</b> : {card.email}
                  </p>
                </span>
                <span>
                  <Button onClick={() => openProfile(card.profileurl)}>
                    View Profile
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
