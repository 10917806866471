import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { BsFillTelephoneFill } from 'react-icons/bs'
import { Button } from 'react-bootstrap';
import ReactGA from 'react-ga'
import { useEffect } from 'react';


const ContactComponent = () => {


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    })
    const handleDownload = () => {
        window.location.href = 'https://anydesk.com/en/downloads/windows';
    };
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4" style={{ backgroundColor: '#E5F0F5' }}>
                        <br></br>
                        <h3 style={{ textEmphasisColor: 'whitesmoke' }}>For B.A(Major & Multidisciplinary)</h3>
                        <p>
                            <h5>Mr. Sk.Raj Ali</h5>
                            <i>Assistant Professor</i>
                            <br></br>

                            <BsFillTelephoneFill /> +91 8585018429 (11 AM. to 4 PM.) (Mon-Sat)
                            <p><FaEnvelope />sk@calcuttagirlscollege.ac.in</p>
                        </p>
                    </div>

                    <div className="col-lg-4" style={{ backgroundColor: '#F2E4ED' }}>
                        <br></br>

                        <h3 style={{ textEmphasisColor: 'whitesmoke' }}>For B.Com( 4 year  & 3 Year Course)</h3>
                        <p>
                            <h5> Mr.Prasenjit Makur</h5>
                            <i>Assistant Professor</i>
                            <br></br>

                            <BsFillTelephoneFill /> +91 9046475814 (11 AM. to 4 PM.) (Mon-Sat)
                            <p><FaEnvelope />pm@calcuttagirlscollege.ac.in</p>
                        </p>
                    </div>



                    <div className="col-lg-4" style={{ backgroundColor: '#E8F3E8' }}>
                        <br></br>
                        <h3 style={{ textEmphasisColor: 'whitesmoke' }}>For B.Sc.(Major & Multidisciplinary)</h3>
                        <p>
                            <h5>Mr. Arkajyoti Jana</h5>
                            <i>Assistant Professor</i>
                            <br></br>
                            <BsFillTelephoneFill /> +91 9910714190 (11 AM. to 4 PM.) (Mon-Sat)
                            <p><FaEnvelope />arkajyotiecon@outlook.com</p>
                        </p>

                    </div>
                </div>
                <br></br>
                <div className='row'>
                
                    <div className="col-lg-4" style={{ backgroundColor: '#E5F0F5' }}>
                    <br></br>
                        <h3 style={{ textEmphasisColor: 'whitesmoke' }}>For any remote support </h3>
                        <p> Mr. Monu Kr. Singh
                         </p>
                         <BsFillTelephoneFill /> +91 9007108662 (11 AM. to 4 PM.) (Mon-Sat)
                         <p><FaEnvelope />admission@calcuttagirlscollege.ac.in</p>
                     <p></p>
                         </div>
                            
                    <div className="col-lg-4" style={{ backgroundColor: '#E8F3E8' }}>
                    <br></br>
                         <h3 style={{ textEmphasisColor: 'whitesmoke' }}>Payment Related Issues </h3>
                         <h5>Mr.Sikandar Rajak</h5>
                          <BsFillTelephoneFill /> +918336867963 (11 AM. to 4 PM.) (Mon-Sat)
                          {/* <p><FaEnvelope /> </p> */}
                            
                            </div>
                </div>
            </div>


        </>
    );
};

export default ContactComponent;
