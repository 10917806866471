import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Meritlistadmincomponent = () => {
  const isAuthenticated = useStoreState((state) => state.user.isAuthenticated);
  const username = useStoreState((state) => state.user.username);

  const [courses, setCourses] = useState([{ category: '', maxRank: '', downloadList: null }]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [isUpdatingButtons, setIsUpdatingButtons] = useState(false);
  const [publicationDate, setPublicationDate] = useState('');
  const [publicationTime, setPublicationTime] = useState('');
  const [publicationPurpose, setPublicationPurpose] = useState('');

  useEffect(() => {
    setCourses([{ category: '', maxRank: '', downloadList: null }]);
    setSelectedCourse('');
  }, []);

  const handleAddCourse = () => {
    setCourses([...courses, { category: '', maxRank: '', downloadList: null }]);
  };

  const handleCourseChange = (index, field, value) => {
    const updatedCourses = [...courses];
    updatedCourses[index][field] = value;
    setCourses(updatedCourses);
  };

  const handleFileUpload = (index, file) => {
    const updatedCourses = [...courses];
    updatedCourses[index].downloadList = file;
    setCourses(updatedCourses);
  };

  const handleAddButton = () => {
    setButtons([...buttons, { name: '', URL: '', position: 'top' }]);
  };

  const handleButtonChange = (index, field, value) => {
    const updatedButtons = [...buttons];
    updatedButtons[index][field] = value;
    setButtons(updatedButtons);
  };

  const handleUpdateButtons = async () => {
    setIsUpdatingButtons(true);

    try {
      for (const button of buttons) {
        await axios.post('https://cgcbackend.onrender.com/buttons', button);
      }
      toast.success('Buttons updated successfully');
    } catch (error) {
      toast.error('An error occurred while updating buttons');
      console.error(error);
    }

    setIsUpdatingButtons(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const filePaths = [];
      await uploadFiles(0, filePaths); // Start recursive file upload
      const excelData = generateExcelData(filePaths);
      await uploadExcel(excelData);
      await updatePublicationDate();
      toast.success('Excel uploaded and publication date updated successfully');
    } catch (error) {
      toast.error('An error occurred');
      console.error(error);
    }

    setIsLoading(false);
    setCourses([{ category: '', maxRank: '', downloadList: null }]);
    setSelectedCourse('');
  };

  const uploadFiles = async (index, filePaths) => {
    if (index >= courses.length) {
      return; // Base case: all files uploaded
    }

    const course = courses[index];

    if (course.downloadList) {
      const formData = new FormData();
      formData.append('file', course.downloadList);

      try {
        const response = await axios.post('https://backend.muoninfotech.com/upload', formData);
        const filePath = response.data.filePath;
        filePaths.push(filePath);
      } catch (error) {
        console.error(`File upload error: ${error}`);
      }
    }

    await uploadFiles(index + 1, filePaths); // Recursive call for the next file
  };

  const generateExcelData = (filePaths) => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    courses.forEach((course, index) => {
      const row = {
        Category: course.category,
        'Max Rank': course.maxRank,
        'Download List': filePaths[index],
      };
      worksheetData.push(row);
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, selectedCourse);

    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelData;
  };

  const uploadExcel = async (excelData) => {
    const formData = new FormData();
    const blob = new Blob([excelData], { type: 'application/vnd.ms-excel' });
    formData.append('file', blob);
    return await axios.post('https://cgcbackend.onrender.com/upload-excel', formData);
  };

  const updatePublicationDate = async () => {
    const date = publicationDate;
    const time = publicationTime;
    const purpose = publicationPurpose;

    try {
      await axios.post('https://cgcbackend.onrender.com/publication', {
        date,
        time,
        purpose,
      });
      setPublicationDate('')
      setPublicationTime('')
      setPublicationPurpose('')
    } catch (error) {
      console.error(`Publication date update error: ${error}`);
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (username !== 'rchowdhury') {
    return <h1>You are NOT Authorized to view this page</h1>;
  }

  return (
    <div className={`container ${isLoading ? 'loading' : ''}`}>
      <p>_____________________________________</p>
      <h2>Merit List Publishing Form</h2>
      <p>_____________________________________</p>
      <select
        className="trialbutton select-line"
        value={selectedCourse}
        onChange={(event) => setSelectedCourse(event.target.value)}
      >
        <option value="">Select Course Type</option>
        <option value="Bengali (Major)">Bengali (Major)</option>
        <option value="Hindi (Major)">Hindi (Major)</option>
        <option value="English (Major)">English (Major)</option>
        <option value="Urdu (Major)">Urdu (Major)</option>
        <option value="History (Major)">History (Major)</option>
        <option value="Education (Major)">Education (Major)</option>
        <option value="Political Science (Major)">Polscience (Major)</option>
        <option value="Economics (Major)">Economics (Major)</option>
        <option value="B.Com ( 4 Year Major)">B.Com (4 Year Major)</option>
        <option value="B.Com (3 Year Degree)">B.Com (3 Year Degree)</option>
        <option value="B.A (Multidisciplinary)">B.A (Multidisciplinary)</option>
        <option value="B.Sc (Multidisciplinary)">B.Sc (Multidisciplinary)</option>
      </select>
      <p>_____________________________________</p>
      {courses.map((course, index) => (
        <>
          <div key={index}>
            <select
              className="trialbutton"
              value={course.category}
              onChange={(event) => handleCourseChange(index, 'category', event.target.value)}
            >
              <option value="">Select Category</option>
              <option value="All Category (Consolidated)">All Category (Consolidated)</option>
              <option value="General">General</option>
              <option value="ST">ST</option>
              <option value="SC">SC</option>
              <option value="OBC-A">OBC-A</option>
              <option value="OBC-B">OBC-B</option>
              <option value="PWD">PWD</option>
            </select>
            <input
              type="number"
              value={course.maxRank}
              onChange={(event) => handleCourseChange(index, 'maxRank', event.target.value)}
              className="trialbutton"
            />
            <input
              type="file"
              onChange={(event) => handleFileUpload(index, event.target.files[0])}
              className="trialbutton"
            />
          </div>
          <p>_____________________________________</p>
        </>
      ))}
      <button className="trialbutton" onClick={handleAddCourse}>
        Add More Category Data
      </button>
      <button className="trialbutton" onClick={handleSubmit}>
        Submit
      </button>
      <p>_____________________________________</p>
      {/* Add Buttons */}
      <h2>Add Buttons</h2>
      <p>_____________________________________</p>
      {buttons.map((button, index) => (
        <div key={index}>
          <input
            type="text"
            value={button.name}
            onChange={(event) => handleButtonChange(index, 'name', event.target.value)}
            placeholder="Button Name"
            className="trialbutton"
          />
          <input
            type="text"
            value={button.URL}
            onChange={(event) => handleButtonChange(index, 'URL', event.target.value)}
            placeholder="Button URL"
            className="trialbutton"
          />
          <select
            value={button.position}
            onChange={(event) => handleButtonChange(index, 'position', event.target.value)}
            className="trialbutton"
          >
            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
          </select>
        </div>
      ))}
      <button className="trialbutton" onClick={handleAddButton}>
        Add Button
      </button>
      <button className="trialbutton" onClick={handleUpdateButtons} disabled={isUpdatingButtons}>
        {isUpdatingButtons ? 'Updating Buttons...' : 'Update Buttons'}
      </button>
      <p>_____________________________________</p>
      {/* Add Publication Date and Time */}
      <h2>Add Publication Date and Time</h2>
      <p>_____________________________________</p>
      <input
        type="date"
        value={publicationDate}
        onChange={(event) => setPublicationDate(event.target.value)}
        className="trialbutton"
      />
      <input
        type="time"
        value={publicationTime}
        onChange={(event) => setPublicationTime(event.target.value)}
        className="trialbutton"
      />
      <textarea
        value={publicationPurpose}
        onChange={(event) => setPublicationPurpose(event.target.value)}
        placeholder="Purpose"
        className="trialbutton"
      />
      <button className="trialbutton" onClick={updatePublicationDate}>
        Submit Date
      </button>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Meritlistadmincomponent;
