import './App.css';
import React, { useState } from "react";
import store from "./store";
import { StoreProvider } from "easy-peasy";
import Home from './Pages/Home';
import Footer from './components/Footer';
import NavbarComponent from './components/NavbarComponent'
import ReactGA from 'react-ga'
import Header from './components/Header'
import TopBar from './components/Topbar'
import Scroller from './components/Scroller'
import SideButton from './components/Impotantlinks';
import Goveningbody from './Pages/Goveningbody';
import Pdesk from './Pages/Pdesk'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './Style.css';
import 'bootstrap/dist/css/bootstrap.css'
import Staff from './Pages/Staff';
import CodeofConduct from './Pages/CodeofConduct';
import AlumniComponent from './Pages/Alumni';
import VerticalTabs from './components/departments/verticaltbs';
import DepartmentObj from './resources/department.json'
import Gallery from './components/gallery/universalgallery';
import Noticpage from './Pages/Notice';
import RichTextEditor from './to_be_deleted/richtextest';
import Admission from './Pages/Admission';
import Calendar from './components/students/Academiccalendar';
import Iqac from './Pages/Iqac';
import Modal from './components/homepage/Modal'
import Nss from './Pages/Nss';
import Ncc from './Pages/Ncc'
import NoticeEditor from './components/admin/noticeedit';
import data from './resources/data.json'
import Dashboard from './components/admin/Dashboard';
import LoginPage from './components/admin/LoginPage';
import Rtiact from './Pages/rtiact';
import Nirf from './Pages/nirf';
import Scholarship from './Pages/scholarship'
import Awards from './Pages/Awards';
import MyAlbum from './Pages/Magazine';
import Sahara from './Pages/Sahara';
import Trial from './to_be_deleted/trial'
import Activity from './Pages/Activity';
import Stsccell from './Pages/Stsccell';
import MinorityCell from './Pages/MinorityCell'
import Antiraggingcell from "./Pages/Antiraggingcell"
import Grivience from './Pages/Grivience'
import Meritlistadmin from './components/admin/meritlistadmin';

function ExcludeComponentsWrapper({ children }) {
  const location = useLocation();

  // Define an array of paths where you want to exclude the components
  const excludedPaths = ["/noticeeditor/", "/dashboard", "/login", "/meritlist"];

  // Check if the current path is in the excludedPaths array
  const shouldExcludeComponents = excludedPaths.includes(location.pathname);
  //console.log(location.pathname)
  //console.log(shouldExcludeComponents)

  // Render the children components conditionally
  return shouldExcludeComponents ? null : children;
}

const TRACKING_ID = 'G-2L8QGRF12V';
ReactGA.initialize(TRACKING_ID);


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };


  return (
    <div className="App">
      <BrowserRouter>
        <ExcludeComponentsWrapper>
          <Modal />
          <SideButton />
          <TopBar />
          <Header />
          <NavbarComponent />
          <Scroller />
        </ExcludeComponentsWrapper>
        <StoreProvider store={store}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/principaldesk' element={<Pdesk />} />
            <Route path='/test' element={<RichTextEditor />} />
            <Route path='/gb' element={<Goveningbody />} />
            <Route path='/staff' element={<Staff />} />
            <Route path='/academiccalendar' element={<Calendar />} />
            <Route path='/coc' element={<CodeofConduct />} />
            <Route path='/alumni' element={<AlumniComponent />} />
            <Route path='/gallery' element={<Gallery data={data} />} />
            <Route path='/department/:title' element={<VerticalTabs data={DepartmentObj} />} />
            <Route path='/notice' element={<Noticpage />} />
            <Route path='/admission' element={<Admission />} />
            <Route path='/iqac' element={<Iqac />} />
            <Route path='/noticeeditor' element={<NoticeEditor />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/dashboard' element={<Dashboard  />} />
            <Route path='/gb' element={<Goveningbody />} />
            <Route path='/staff' element={<Staff />} />
            <Route path='/coc' element={<CodeofConduct />} />
            <Route path='/alumni' element={<AlumniComponent />} />
            <Route path='/gallery' element={<Gallery data={data} />} />
            <Route path='/department/:title' element={<VerticalTabs data={DepartmentObj} />} />
            <Route path='/notice' element={<Noticpage />} />
            <Route path='/admission' element={<Admission />} />
            <Route path='/iqac' element={<Iqac />} />
            <Route path='/nss' element={<Nss />} />
            <Route path='/ncc' element={<Ncc />} />
            <Route path='/rti' element={<Rtiact />} />
            <Route path='/nirf' element={<Nirf />} />
            <Route path='/scholarship' element={<Scholarship />} />
            <Route path='/awards' element={<Awards />} />
            <Route path='/magazine' element={<MyAlbum />} />
            <Route path='/sahara' element={<Sahara />} />
            <Route path='/activity' element={<Activity />} />
            <Route path='/stsccell' element={<Stsccell />} />
            <Route path='/trial' element={<Trial />} />
            <Route path='/minority' element={<MinorityCell />} />
            <Route path='/anti' element={<Antiraggingcell />} />
            <Route path='/grievance' element={<Grivience />} />
            <Route path='/meritlist' element={<Meritlistadmin />} />
          </Routes>
        </StoreProvider>
        <ExcludeComponentsWrapper>
          <Footer />
        </ExcludeComponentsWrapper>
      </BrowserRouter>

    </div>
  );
}

export default App;
