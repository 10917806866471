import React from 'react'
import TableComponent from '../components/TableComponent'
import image1 from '../Image/gb1.jpg'
import image2 from '../Image/gb2.jpg'
import image3 from '../Image/gb3.jpg'


import CarouselComponent from '../components/CarouselComponent';

const Goveningbody = () => {
    const temp = [
        {  name: 'Md.Nadimul Haque', designation: 'President, (Honourable Rajyasabha Member)' },
        {  name: 'Dr.Satya Upadhyaya', designation: 'Principal' },
        {  name: 'Dr.Indranil Kar', designation: 'Govt. Nominee' },
        {  name: 'Dr. Dabir Ahmed', designation: 'Nominee Of W.B. State Council Of Higher Education ' },
        {  name: 'Mr. Sandipan Saha ', designation: 'Govt. Nominee' },
        {  name: 'Mrs. Maitrayee Bhattacharya ', designation: 'Syndicate Nominee' },
        {  name: 'Dr. Debasis Das', designation: 'Syndicate Nominee' },
        {  name: 'Dr.Nandini Bhattacharya ', designation: 'Teachers’ Representative' },
        {  name: 'Dr.Nayeem Ahmed', designation: 'Teachers’ Representative' },
        {  name: 'Mr.Prem Kumar Ghosh', designation: 'Teachers’ Representative' },
        {  name: 'Mr. Sikandar Rajak ', designation: 'Non Teaching Representative' },
        // Add more data here...
      ];

      const gbImages=[image1,image2,image3];

    
  return (
    <>
       <div> <CarouselComponent data={gbImages}/></div><br/>
    <div><h2>Governing Body</h2>
    <TableComponent tabledata={temp} /></div>
    </>
  )
}

export default Goveningbody