import React from 'react'
import CardImage from '../components/CardImage'
import data from '../resources/activity.json'

const Activity = () => {
  return (
    <>
    <h1>Student's Activity</h1>
    <br></br>
    <CardImage events={data}/>
    </>
  )
}

export default Activity