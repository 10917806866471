import React, { useState } from 'react';
import AdminTable from './AdminTable';
import data from './../../resources/department.json';
import Merit from '../../Pages/Merit';

const Eligibility = () => {
  const [filteredItemHeader, setFilteredItemHeader] = useState('');

  const accordionItems1 = data.filter((tab) => tab.eligibility !== undefined);
  const accordionItems = accordionItems1.map((item) => item.eligibility);

  const handleAccordianClick = (header) => {
    setFilteredItemHeader(header);
  };

  const handleDropdownChange = (event) => {
    const selectedHeader = event.target.value;
    setFilteredItemHeader(selectedHeader);
  };

  return (
    <>
      <h1>Eligibility for B.A./B.Sc/B.Com(Major./Multidisciplinary/Degree)</h1>
      <div className='filters' style={{ textAlign: 'center' }}>
        <label htmlFor="name-filter">Select a Course:</label>
        <select value={filteredItemHeader} onChange={handleDropdownChange}>
          <option value="">All</option>
          {accordionItems.map((item) => (
            <option value={item.heading} key={item.id}>
              {item.heading}
            </option>
          ))}
        </select>
      </div>
      <div className="accordion" id="accordionExample">
        {accordionItems1.map((item) => (
          <div
            className={`accordion-item ${filteredItemHeader !== '' && item.eligibility.heading !== filteredItemHeader ? 'hidden' : ''}`}
            key={item.id}
          >
            <h2 className="accordion-header" id={`heading${item.id}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${item.id}`}
                aria-expanded="true"
                aria-controls={item.id}
                onClick={() => handleAccordianClick(item.eligibility.heading)}
              >
                {item.eligibility.heading}
              </button>
            </h2>
            <div
              id={item.id}
              className={`accordion-collapse collapse ${item.eligibility.heading === filteredItemHeader ? 'show' : ''}`}
              aria-labelledby={`heading${item.id}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <AdminTable data={item?.eligibility.content} />
              </div>
            </div>
          </div>
        ))}
        <p>
          <i>**For more details on intake capacity, please refer seat Matrix.</i>
        </p>
      </div>
    </>
  );
};

export default Eligibility;
