import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const NssComponent = ({data}) => {
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filteredNssData, setfilteredNssData] = useState(data);
  
  
  
  const openNotice = (url) => {
    window.open(url, '_blank');
  };
  const handleFilterClick = () => {
    const filteredData = data.filter((nss) => {
      return (
        nss.title.toLowerCase().includes(filterTitle.toLowerCase()) &&
        nss.date.includes(filterDate)
      );
    });
    setfilteredNssData(filteredData);
  };

const handleClearFilters = () => {
    setFilterTitle('');
    setFilterDate('');
    setfilteredNssData(data);
  };

  const handleTitleChange = (event) => {
    setFilterTitle(event.target.value);
  };

  const handleDateChange = (date) => {
    setFilterDate(date);
  };
  
  return (
   <>
     <div className="container">
      <h3>NSS Events</h3>
      <div className="filter-form" style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <div style={{ textAlign: 'right' }}>
    {/* <label htmlFor="filterTitle">Filter Title</label>
    <br /> */}
    <input
      type="text"
      id="filterTitle"
      value={filterTitle}
      onChange={handleTitleChange}
      placeholder="Enter filter title"
      className="form-control"
      style={{ width: '90%' }}
    />
  </div>
  <div style={{ textAlign: 'right' }}>
    
    <DatePicker
  id="filterDate"
  selected={filterDate ? new Date(filterDate) : null}
  onChange={date => handleDateChange(date.toISOString().split('T')[0])}
  placeholderText="Select filter date"
  className="form-control"
  dateFormat="yyyy-MM-dd"
  style={{ width: '90%' }}
  showTimeSelect={false}
/>
  </div>
</div>
<div className="button-group" style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '150px',  marginTop: '20px'  }}>
    <Button variant="primary" onClick={handleFilterClick} className='btn btn-primary btn-sm' style={{marginRight: '50px'}}>
      Filter
    </Button>
    <Button variant="primary" onClick={handleClearFilters} className='btn btn-primary btn-sm'>
      Clear Filters
    </Button>
  </div>
<br></br>
      <table className="table">
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>Title</th>
            <th>Event Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredNssData.map((nss) => (
            <tr key={nss.id}>
              <td>
                <img
                  src={nss.thumbnail}
                  alt="NSS Thumbnail"
                  className="img-thumbnail"
                  width='90px'
                  height='90px'
                />
              </td>
              <td>{nss.title}</td>
              <td>{nss.date}</td>
              <td>
                <Button variant="primary" onClick={() => openNotice(nss.viewdetail)}>View Details</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   
   </>
  );
};

export default NssComponent;
