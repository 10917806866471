import React from 'react'

const Description = ({data}) => {

  return (
    <div className="container-md" > 
      <div className='container-sm'>
      <p >{data}</p>
      </div>
</div>
  )
}

export default Description