import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const AlumniComponent = () => {
  const alumniData = [
    {
      id: 1,
      thumbnail: 'image1.jpg',
      title: 'Alumni Program 1',
      date: '2023-06-15',
    },
    {
      id: 2,
      thumbnail: 'image2.jpg',
      title: 'Alumni Program 2',
      date: '2023-07-20',
    },
    // Add more alumni data as needed
  ];

  const [filterTitle, setFilterTitle] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filteredAlumniData, setFilteredAlumniData] = useState(alumniData);

  const handleFilterClick = () => {
    const filteredData = alumniData.filter((alumni) => {
      return (
        alumni.title.toLowerCase().includes(filterTitle.toLowerCase()) &&
        alumni.date.includes(filterDate)
      );
    });
    setFilteredAlumniData(filteredData);
  };

  const handleClearFilters = () => {
    setFilterTitle('');
    setFilterDate('');
    setFilteredAlumniData(alumniData);
  };

  const handleTitleChange = (event) => {
    setFilterTitle(event.target.value);
  };

  const handleDateChange = (date) => {
    setFilterDate(date);
  };
  
  

  return (
   <>
     <div className="container">
      <h3>Alumni Events</h3>
      <div className="filter-form" style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <div style={{ textAlign: 'right' }}>
    {/* <label htmlFor="filterTitle">Filter Title</label>
    <br /> */}
    <input
      type="text"
      id="filterTitle"
      value={filterTitle}
      onChange={handleTitleChange}
      placeholder="Enter filter title"
      className="form-control"
      style={{ width: '90%' }}
    />
  </div>
  <div style={{ textAlign: 'right' }}>
    {/* <label htmlFor="filterDate">Filter Date</label>
    <br /> */}
    <DatePicker
  id="filterDate"
  selected={filterDate ? new Date(filterDate) : null}
  onChange={date => handleDateChange(date.toISOString().split('T')[0])}
  placeholderText="Select filter date"
  className="form-control"
  dateFormat="yyyy-MM-dd"
  style={{ width: '90%' }}
  showTimeSelect={false}
/>
  </div>
</div>
<div className="button-group" style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '150px',  marginTop: '20px'  }}>
    <Button variant="primary" onClick={handleFilterClick} className='btn btn-primary btn-sm' style={{marginRight: '50px'}}>
      Filter
    </Button>
    <Button variant="primary" onClick={handleClearFilters} className='btn btn-primary btn-sm'>
      Clear Filters
    </Button>
  </div>

      <table className="table">
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>Title</th>
            <th>Event Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredAlumniData.map((alumni) => (
            <tr key={alumni.id}>
              <td>
                <img
                  src={alumni.thumbnail}
                  alt="Alumni Thumbnail"
                  className="img-thumbnail"
                />
              </td>
              <td>{alumni.title}</td>
              <td>{alumni.date}</td>
              <td>
                <Button variant="primary">View Details</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   
   </>
  );
};

export default AlumniComponent;
