import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';

const Calendar = () => {
  const holidays = [
    { title: 'All Day Event', start: '2023-06-01' },
    { title: 'Rendezvous', start: '2023-06-07', end: '2023-06-10' },
    { groupId: '999', title: 'Repeating Event', start: '2023-06-09T16:00:00+00:00' },
  ];

  return (
    <div style={{ width: '100%',  overflow: 'hidden', paddingRight: '10px' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={holidays}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay',
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
