import React from 'react';
import AdmissionSidebar from '../components/AdmissionSidebar';


const Admission = (data) => {
  return (
    <>
      <header className=" py-3" style={{backgroundColor:"rgb(246, 186, 111"}}>
      <div className="container-fluid text-white">
        <h1 className="m-0">ONLINE ADMISSION FOR UNDERGRADUATE 2024-25</h1>
      </div>
    </header>
   <AdmissionSidebar/>
  
    </>
  );
};

export default Admission;
