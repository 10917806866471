import React from 'react';

const Subheader = ({data}) => {
    //console.log(data)
  return (
    <header className=" py-3" style={{backgroundColor:"rgb(246, 186, 111"}}>
      <div className="container-fluid text-white">
        <h1 className="m-0"><b>{data[0]}</b></h1>
      </div>
    </header>
    
  );
};

export default Subheader;
