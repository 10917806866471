import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  outcomeItem: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0.5)
    },
    border: "1px solid #ccc",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    position: "relative"
  },
  outcomeLabel: {
    position: "absolute",
    top: '-7px',
    left: '-7px',
    background: 'rgb(109, 169, 228)',
    color: "#fff",
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: "bold"
  }
}));

const ProgramOutcomes = ({ programOutcomes }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Program Outcomes
      </Typography>
      <Grid container>
        {programOutcomes?.map((outcome, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div className={classes.outcomeItem}>
              <span className={classes.outcomeLabel}>{`PO - ${
                index + 1
              }`}</span>
              <Typography variant="body1">{outcome}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProgramOutcomes;
