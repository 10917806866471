import React, { useState } from 'react';

const CardImage = ({events}) => {

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleCardClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="activitycard-container">
      {events.map((event, index) => (
        <div className="activitycard" key={index} onClick={() => handleCardClick(event)}>
          <div className="event-image" style={{ backgroundImage: `url(${event.EventImage})` }} />
          <div className="activitycard-body">
            <p dangerouslySetInnerHTML={{ __html: event.text }} />
          </div>
        </div>
      ))}
      {selectedEvent && (
        <div className="cipopup">
          <div className="cipopup-content">
            <button className="close-button" onClick={handleClosePopup}>
              Close
            </button>
            <div className="image-grid">
              {selectedEvent.imageURLs.map((image, index) => (
                <div className="image-item" key={index}>
                  <img src={image} alt={` ${index}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardImage;
