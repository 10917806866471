import React, { useState, useEffect } from 'react';
import Slider from '../components/homepage/Slider';
import AboutUs from '../components/homepage/AboutUs';
import PrincipalDesk from '../components/homepage/PrincipalDesk';
import NoticesSection from '../sections/NoticesSection';
import CounterComponent from '../components/homepage/CounterComponent';
import Announcements from '../components/homepage/Anouncement';
import './Home.css';
import Youtubeslider from '../components/homepage/YoutubeSlider';

const Home = () => {
  const [noticeData, setNoticeData] = useState([]);

  useEffect(() => {
    // Fetch notice data from the endpoint
    const fetchNotices = async () => {
      try {
        const response = await fetch('https://cgcbackend.onrender.com/notices');
        const data = await response.json();
        //console.log(data);
        setNoticeData(data);
      } catch (error) {
        console.error('Failed to fetch notices:', error);
      }
    };

    fetchNotices();
  }, []);

  return (
    <>
      <Slider />
      <div className="split-section">
        <div className="left-content">
          <AboutUs />
        </div>
        <div className="right-content">
          <PrincipalDesk />
        </div>
      </div>
      <div className='divgap'>*</div>
      <div className="split-section-second">
        <div className="left-content">
          <NoticesSection data={noticeData} />
        </div>
        <div className="right-content">
          <Announcements videoId={'p9fpv9sN1OQ'} />
        </div>
      </div>
      <div className='sectionbg'>
        <div className='counter'>
          <CounterComponent />
        </div>
      </div>
      {/* <CollegeHighlights /> */}
      <br></br>
      <Youtubeslider />
    </>
  );
};

export default Home;
