import { createStore, action } from "easy-peasy";

const store = createStore({
  user: {
    isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
    username: localStorage.getItem("username") || "",
    setIsAuthenticated: action((state, payload) => {
      state.isAuthenticated = payload;
      localStorage.setItem("isAuthenticated", payload);
    }),
    setUsername: action((state, payload) => {
      state.username = payload;
      localStorage.setItem("username", payload);
    }),
    logout: action((state) => {
      state.isAuthenticated = false;
      state.username = "";
      localStorage.setItem("isAuthenticated", "false");
      localStorage.removeItem("username");
    }),
  },
});

export default store;
