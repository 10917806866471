import React, { useState } from "react";

const HierarchyComponent = ({ data }) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const handleItemToggle = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const renderCombination = (combinations) => {
    return (
      <table className="combinations-table">
        <thead>
          <tr>
            <th>GE I</th>
            <th>GE II</th>
            <th>Fees</th>
          </tr>
        </thead>
        <tbody>
          {combinations.map((combination, index) => (
            <tr key={index} className="combination-row">
              <td>{combination["GE I"]}</td>
              <td>{combination["GE II"]}</td>
              <td>{combination["Fees"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderCore = (core, coreIndex) => {
    const isExpanded = expandedItems.includes(coreIndex);

    return (
      <div key={coreIndex} className="core">
        <div
          className={`core-title ${isExpanded ? "expanded" : ""}`}
          onClick={() => handleItemToggle(coreIndex)}
        >
          {core["stream"]}
        </div>
        {isExpanded && <div className="combinations">{renderCombination(core["combinations"])}</div>}
      </div>
    );
  };

  return (
    <div className="hierarchy-component">
      {data.map((item, index) => (
        <div key={index} className="stream">
          <div className="stream-title">{item["stream"]}</div>
          {item["core"].map((core, coreIndex) => renderCore(core, coreIndex))}
        </div>
      ))}
    </div>
  );
};

export default HierarchyComponent;
