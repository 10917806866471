


import React from 'react';
import { Carousel, Button } from 'react-bootstrap';

const CarouselComponent = ({ data }) => {
  return (
    <div>
      <style>
        {`
          .slider-image {
            object-fit: cover;
            object-position: center;
            height: 450px;
          }
          .learn-more-button {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }
        `}
      </style>
      <Carousel>
        {data.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 slider-image"
              src={image}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption>
              
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent; 