import React, { useEffect, useState } from 'react';
import CountdownTimer from '../components/Amission/CountdownTimer';
import MultiTableComponent from '../components/Amission/MultiTableComponent';
import datajson from '../resources/AdmissionList.json'
import Table from '../components/table';

function MeritList() {
  const [data, setData] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publicationTime, setPublicationTime] = useState(null);

  useEffect(() => {
    fetchData();
    fetchButtons();
    fetchPublicationTime();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://cgcbackend.onrender.com/fetch-excel-data');
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const fetchButtons = async () => {
    try {
      const response = await fetch('https://cgcbackend.onrender.com/buttons');
      const jsonData = await response.json();
      setButtons(jsonData);
    } catch (error) {
      console.error('Failed to fetch buttons:', error);
    }
  };

  const fetchPublicationTime = async () => {
    try {
      const response = await fetch('https://cgcbackend.onrender.com/publication?purpose=meritlist');
      const jsonData = await response.json();
      const publicationDate = new Date(jsonData.publicationdate);
      setPublicationTime(publicationDate);
    } catch (error) {
      console.error('Failed to fetch publication time:', error);
    }
  };

  const headerData = ['Category Wise Admission Call List  '];

  const currentTime = new Date(); // Get current time
  const publicationTimeOnly = new Date(publicationTime).getTime();

  if (publicationTime && currentTime < publicationTime) {
    return (
      <div className="image-container">
        <img src="https://static.muoninfotech.com/./assets/Coming soon png/coming soon.png" alt="Coming Soon" className="coming-soon-image" />
        <h2 className="unique-heading-classname">We are still evaluating.<br></br> Merit List will be out Soon!</h2>
        <p>Check back in:</p>
        <CountdownTimer targetTime={publicationTimeOnly} />
      </div>
    );
  }

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <>
    <h2>All Category Admission Call List</h2>
    <Table data = {datajson} text={"Download"} />
    </>
  );
}

export default MeritList;
