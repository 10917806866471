import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import "bootstrap/dist/css/bootstrap.css";
import './admin.css';
import Logo from "../../Image/pic.png"; // Path to your logo image

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function TextLinkExample() {
  const navigate = useNavigate();
  const logout = useStoreActions((actions) => actions.user.logout);
  const username = useStoreState((state) => state.user.username);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Navbar className="bg-body-tertiary" expand="lg">
      <Container>
        <Navbar.Brand>
          <img src={Logo} alt="Logo" width="50" height="50" /> {/* Adjust the width and height according to your logo */}
          Calcutta Girls' College
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/dashboard">
              Add Notice
            </Nav.Link>
            <Nav.Link as={Link} to="/meritlist">
              Add Merit List
            </Nav.Link>
          </Nav>
          <Navbar.Text style={{fontWeight: 'bold'}}>
            Signed in as: {username}
            <button
              onClick={handleLogout}
              style={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '5px 10px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              Logout
            </button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TextLinkExample;
