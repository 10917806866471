import React from 'react'
import data from '../resources/Cells.json'
import Cells from '../components/Cells'

const Stsccell = () => {
  return (
    <>
     
    <Cells data={data} itemname={"SC/ST Cell"}/>
    </>
  )
}

export default Stsccell