import React, { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";

const NoticeEditor = () => {
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const editorRef = useRef(null);
  const unique_id = uuid();
  const [text, setText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const fileInputRef = useRef(null);

  // Add attachment state and handler
  const [attachment, setAttachment] = useState(null);
  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const [updatedNotice, setUpdatedNotice] = useState({
    id: null,
    title: "",
    description: "",
    date: "",
    destination: "",
    attachment: null, // Add the "attachment" attribute to the notice object
  });
  const [newNotice, setNewNotice] = useState({
    id: unique_id.slice(0, 8),
    title: "",
    description: "",
    date: "",
    destination: "",
    attachment: null, // Add the "attachment" attribute to the notice object
  });
  //console.log(newNotice);
  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const response = await axios.get(
        "https://cgcbackend.onrender.com/notices"
      );
      setNotices(response.data);
    } catch (error) {
      console.error("Failed to fetch notices:", error);
    }
  };

  const handleNoticeChange = (notice) => {
    setSelectedNotice(notice);
    setUpdatedNotice({
      id: notice.id,
      title: notice.title,
      description: notice.description,
      date: notice.date,
      destination: "",
    });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setSelectedNotice(null);
    setIsPopupOpen(false);
    setUpdatedNotice({
      id: null,
      title: "",
      description: "",
      date: "",
      destination: "",
    });
  };

  const handleInputChange = (e, editor) => {
    const { name, value } = e.target;
    if (name === "date") {
      let formatted = new Date(value).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      setUpdatedNotice((prevNotice) => ({
        ...prevNotice,
        [name]: formatted.toString,
      }));
      setSelectedDate(formatted);
    } else if (name === "attachment") {
      setUpdatedNotice((prevNotice) => ({
        ...prevNotice,
        [name]: e.target.files[0], // Set the attachment file object
      }));
    } else {
      setUpdatedNotice((prevNotice) => ({
        ...prevNotice,
        [name]: value,
      }));
    }
  };

  const handleNewNoticeInputChange = (e, editor) => {
    const { name, value } = e.target;
    if (name === "date") {
      let formatted = new Date(value).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      setNewNotice((prevNotice) => ({
        ...prevNotice,
        [name]: formatted.toString,
      }));
      setSelectedDate(formatted);
    } else if (name === "attachment") {
      setNewNotice((prevNotice) => ({
        ...prevNotice,
        [name]: e.target.files[0], // Set the attachment file object
      }));
    } else {
      setNewNotice((prevNotice) => ({
        ...prevNotice,
        [name]: value,
      }));
    }
  };

  const handleNoticeUpdate = async (id) => {
    try {
      if (updatedNotice.attachment) {
        const formData = new FormData();
        formData.append("file", updatedNotice.attachment);
        const response = await axios.post(
          "https://backend.muoninfotech.com/upload",
          formData
        );

        // Update the notice with the attachment URL
        updatedNotice.attachment = response.data.filePath.toString();
        console.log(response.data.filePath);
      }
      await axios.put(
        `https://cgcbackend.onrender.com/notices/${id.id}/items/${id.itemId}`,
        updatedNotice
      );
      fetchNotices();
      setSelectedNotice(null);
      setUpdatedNotice({
        id: null,
        title: "",
        description: "",
        date: "",
        destination: "",
        attachment: null,
      });
    } catch (error) {
      console.error("Failed to update notice:", error);
    }
  };

  const handleNoticeDelete = async (id) => {
    try {
      await axios.delete(
        `https://cgcbackend.onrender.com/notices/${id.id}/items/${id.itemId}`
      );
      fetchNotices();
      setSelectedNotice(null);
      setUpdatedNotice({
        id: null,
        title: "",
        description: "",
        date: "",
        destination: "",
      });
    } catch (error) {
      console.error("Failed to delete notice:", error);
    }
  };

  const handleNewNoticeSubmit = async (notice) => {
    try {
      //console.log(newNotice);
      // Upload the attachment file
      if (newNotice.attachment) {
        const formData = new FormData();
        formData.append("file", newNotice.attachment);
        const response = await axios.post(
          "https://backend.muoninfotech.com/upload",
          formData
        );

        // Update the notice with the attachment URL
        newNotice.attachment = response.data.filePath.toString();
        console.log(response.data.filePath);
      }
      await axios.post(
        `https://cgcbackend.onrender.com/notices/${notice.id}/items`,
        newNotice
      );
      fetchNotices();
      setNewNotice({
        id: unique_id.slice(0, 8),
        title: "",
        description: "",
        date: "",
        destination: "",
        attachment: null,
      });
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Failed to add new notice:", error);
    }
  };

  const toggleCategoryExpansion = (category) => {
    setExpandedCategories((prevExpandedCategories) => {
      if (prevExpandedCategories.includes(category)) {
        return prevExpandedCategories.filter((cat) => cat !== category);
      } else {
        return [...prevExpandedCategories, category];
      }
    });
  };

  const renderCategoryNotices = (category, isExpanded) => {
    const categoryNotices = notices.filter(
      (notice) => notice.title === category
    );
    //console.log(categoryNotices[0]?.items[0]?.id);
    //console.log(notices);
    return (
      <>
        {isExpanded &&
          categoryNotices[0]?.items.map((notice) => (
            <div key={notice.id} className="bard">
              <div className="bard-header">
                <h3>{notice.title}</h3>
                <p>
                  {notice.date.includes(",")
                    ? notice.date
                    : new Date(notice.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                </p>
                <button
                  className="edit-button"
                  onClick={() => handleNoticeChange(notice)}
                >
                  Edit
                </button>
              </div>
              {selectedNotice && selectedNotice.id === notice.id && (
                <>
                  {" "}
                  {isPopupOpen && (
                    <div className="blop">
                      <h4>Edit Notice</h4>
                      <input
                        type="text"
                        name="title"
                        value={updatedNotice.title}
                        onChange={handleInputChange}
                      />
                      <input
                        type="file"
                        name="attachment"
                        onChange={handleInputChange}
                      />

                      <div>
                        <DatePicker
                          id="date"
                          name="date"
                          selected={new Date(updatedNotice.date)}
                          onChange={(date) =>
                            setUpdatedNotice((prevNotice) => ({
                              ...prevNotice,
                              date,
                            }))
                          }
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Select Date"
                        />
                      </div>
                      {/* <Editor
                      name="description"
                      value={updatedNotice.description}
                      onEditorChange={(newValue, editor) => {
                        setUpdatedNotice((prevNotice) => ({
                          ...prevNotice,
                          "description": newValue,
                        }));
                        setText(editor.getContent({ format: 'text' }));
                      }}
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: ['advlist autolink lists link image', 'charmap print preview anchor help'],
                        toolbar:
                          'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link image',
                      }}
                    /> */}
                      <ReactQuill
                        value={updatedNotice.description}
                        onChange={(newValue, delta, source, editor) => {
                          setUpdatedNotice((prevNotice) => ({
                            ...prevNotice,
                            description: newValue,
                          }));
                          setText(editor.getText());
                        }}
                        ref={editorRef}
                        modules={{
                          toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ size: [] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "font",
                          "size",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                          "video",
                        ]}
                        style={{ height: 300 }}
                      />
                      <div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </div>

                      <button
                        onClick={() =>
                          handleNoticeUpdate({
                            id:
                              category === "Notice Board"
                                ? 1
                                : category === "Official Notice"
                                ? 2
                                : 3,
                            itemId: notice.id,
                          })
                        }
                      >
                        Save
                      </button>
                      <button
                        onClick={() =>
                          handleNoticeDelete({
                            id:
                              category === "Notice Board"
                                ? 1
                                : category === "Official Notice"
                                ? 2
                                : 3,
                            itemId: notice.id,
                          })
                        }
                      >
                        Delete
                      </button>
                      <button onClick={closePopup}>Cancel</button>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
      </>
    );
  };

  const renderNotices = () => {
    return (
      <div>
        <div className="category">
          <div className="heady">
            <h2>Notice Board</h2>
            <button
              className="expand-button"
              onClick={() => toggleCategoryExpansion("Notice Board")}
            >
              {expandedCategories.includes("Notice Board") ? "-" : "+"}
            </button>
          </div>
          <div className="notice-cards">
            {renderCategoryNotices(
              "Notice Board",
              expandedCategories.includes("Notice Board")
            )}
          </div>
        </div>
        <div className="category">
          <div className="heady">
            <h2>Official Notice</h2>
            <button
              className="expand-button"
              onClick={() => toggleCategoryExpansion("Official Notice")}
            >
              {expandedCategories.includes("Official Notice") ? "-" : "+"}
            </button>
          </div>
          <div className="notice-cards">
            {renderCategoryNotices(
              "Official Notice",
              expandedCategories.includes("Official Notice")
            )}
          </div>
        </div>
        <div className="category">
          <div className="heady">
            <h2>Tender</h2>
            <button
              className="expand-button"
              onClick={() => toggleCategoryExpansion("Tender")}
            >
              {expandedCategories.includes("Tender") ? "-" : "+"}
            </button>
          </div>
          <div className="notice-cards">
            {renderCategoryNotices(
              "Tender",
              expandedCategories.includes("Tender")
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="notice-editor">
      <h2>Notice Editor</h2>
      <div className="add-notice">
        <h3>Add New Notice</h3>
        <input
          type="text"
          name="id"
          value={newNotice.id}
          onChange={handleNewNoticeInputChange}
          placeholder="ID"
          disabled
        />
        <input
          type="text"
          name="title"
          value={newNotice.title}
          onChange={handleNewNoticeInputChange}
          placeholder="Title"
        />
        <input
          type="file"
          name="attachment"
          onChange={handleNewNoticeInputChange}
          ref={fileInputRef}
        />
        <DatePicker
          id="date"
          name="date"
          selected={newNotice.date}
          onChange={(date) =>
            setNewNotice((prevNotice) => ({ ...prevNotice, date }))
          }
          dateFormat="MMMM d, yyyy"
          placeholderText="Select Date"
        />
        {/* <Editor
          name="description"
          value={newNotice.description}
          onEditorChange={(newValue, editor) => {
            setNewNotice((prevNotice) => ({
              ...prevNotice,
              "description": newValue,
            }));
            setText(editor.getContent({ format: 'text' }));
          }}
          onInit={(e, editor) => (editorRef.current = editor)}
          init={{
            height: 300,
            menubar: false,
            plugins: ['advlist autolink lists link image', 'charmap print preview anchor help'],
            toolbar:
              'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link image',
          }}
        /> */}
        <ReactQuill
          value={newNotice.description}
          onChange={(newValue, delta, source, editor) => {
            setNewNotice((prevNotice) => ({
              ...prevNotice,
              description: newValue,
            }));
            setText(editor.getText());
          }}
          ref={editorRef}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image", "video"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          style={{ height: 300 }}
        />
        <div>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div className="dropdown-container">
          <label htmlFor="destination">Category:</label>
          <select
            id="destination"
            name="destination"
            value={newNotice.destination}
            onChange={handleNewNoticeInputChange}
          >
            <option value="">Select Category</option>
            <option value="1">Notice Board</option>
            <option value="2">Official Notice</option>
            <option value="3">Tender</option>
          </select>
        </div>
        <button
          onClick={() =>
            handleNewNoticeSubmit({ id: newNotice.destination, itemId: 5 })
          }
        >
          Add Notice
        </button>
      </div>
      {renderNotices()}
    </div>
  );
};

export default NoticeEditor;
