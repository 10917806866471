import React from 'react';
import data from '../resources/sahara.json'
import dataws from '../resources/saharaworkshop.json'
import TextImage from '../components/TextImage';

const Sahara = () => {
    return (
        <div className="App">
            <h1 style={{
                borderBottom: '2px solid rgb(109, 169, 228)',
                display: 'inline-block',
                marginTop: '45px'
            }}>CGC Sahara</h1>
            <TextImage data={data} />
            <h1 style={{
                borderBottom: '2px solid rgb(109, 169, 228)',
                display: 'inline-block',
                marginTop: '45px'
            }}>CGC Sahara Workshop</h1>
            <TextImage data={dataws} />
        </div>

    );
};

export default Sahara;