import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const TableComponent = (data) => {

  return (
    <div className="container">
      <table className="table table-bordered table-striped custom-table"> {/* Add the "custom-table" class */}
        <thead>
          <tr>
            <th>Name of the Member</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          {data.tabledata.map((row) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.designation}</td>
            </tr> 
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
