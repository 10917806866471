import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <section className="about-us">
      <Container fluid>
        <Row>
          <Col>
            <h1 className="heading-text">ABOUT US</h1>
            <div className={`about-content ${expanded ? 'expanded' : ''}`}>
              <p>
                Calcutta Girls’ College has established itself as an institution of higher learning that encourages young women towards academic betterment, self-reliance, and gaining awareness of a meaningful role in society.
                {expanded || window.innerWidth >= 768 ? (
                  <>
                    The college offers its students modernized academic and infrastructural facilities, individual attention, provisions for various co-curricular activities, programs for career guidance and placements. The college has been recognized by the UGC under 2(b) and 12(f) and offers honors courses in B.A. (Bengali, Education, English, Hindi, History, Political Science, and Urdu) B.Sc. (Economics) and B.com streams along with General courses. The college also functions as the Study Centre for Postgraduate classes under the Directorate of Distance Learning program of Vidyasagar University (Bengali, Commerce, English, History, and Political Science).
                  </>
                ) : (
                  <span className="read-more-text" onClick={toggleExpansion}>
                    .. Read More
                  </span>
                )}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
