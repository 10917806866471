import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import image1 from '../../Image/01.jpg';
import image2 from '../../Image/02.jpg';
import image3 from '../../Image/03.jpg';
import image4 from '../../Image/04.jpg';
import image5 from '../../Image/05.jpg';
import image6 from '../../Image/06.jpg';
import image7 from '../../Image/07.jpg';
import image8 from '../../Image/08.jpg';
import image9 from '../../Image/09.jpg';

const ImageSlider = () => {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];

  return (
    <div>
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 slider-image"
              src={image}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption>
              <Button variant="primary" className="learn-more-button">
                Learn More
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
