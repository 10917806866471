import React from 'react';
import Table from '../../components/table';

const Documents = ({ data }) => {

  return (
    <div>
      
      {data.map((item, index) => (
        <div key={index}>
          <h2>{item.name}</h2>
          <Table data={item.value} text={"Use Service"} />
        </div>
      ))}
    </div>
  );
};

export default Documents;
