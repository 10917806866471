import React from 'react';

const YoutubeVideo = ({ videoId }) => {
  return (
    <>
      <h2 style={{ marginTop: '-50px' }}>Latest Updates</h2>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
          title="YouTube Video"
        ></iframe>
        <iframe
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
          title="YouTube Video"
        ></iframe>
      </div>
    </>
  );
};

export default YoutubeVideo;
