import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './PrincipalDesk.css'; // Import your custom CSS file for styling
import image from '../../Image/principal.jpg';

const Component = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className="image-container">
            <img className="circular-image" src={image} alt="Profile" />
          </div>
          <div className="text-container">
            <span>Dr.Satya Upadhyay | Principal/Secretary</span>
            <br />
            <Link to="/principaldesk" className="principal-link">Principal's Desk</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
