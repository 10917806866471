import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Eligibility from './Amission/Eligibility';
import SeatMatrix from './Amission/Seatmatrix'
import { Button} from 'react-bootstrap';
import SubjectComponent from './Amission/SubjectComponent';
import RulesAndRegulations from './Amission/Rightbar'
import ContactComponent from './Amission/ContactUs';
import Feestructure from '../Pages/Feestructure';
import DocumentsRequired from '../Pages/DocumentsRequired';
import MeritList from '../Pages/MeritList';
import { Link } from 'react-router-dom';
import Table from '../components/table';
import pdata from "../resources/provisionallist.json"


const AdmissionSidebar = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { title } = useParams();
  const contentRef = useRef(null);

  
  
  const handleTabClick = (index) => {
    setActiveTab(index);
    setIsMenuOpen(false);
  };


  const handleListClick = () => {
  
    setActiveTab(1);
  };
  const handleRegisterClick = () => {
    window.location.href = 'https://enrollonline.co.in/Registration/Apply/CGC';
    setActiveTab(1);
  };
  const handleInstructionClick = () => {
     window.location.href = 'https://static.muoninfotech.com/assets/notice/071820223305.pdf';
  };
  const handleAdmissionClick = () => {
     window.location.href = 'https://static.muoninfotech.com/assets/notice/Adimssion%20Schedule%202022-23[1].pdf';
  };

  const handlePaymentClick = () => {
    window.location.href = 'https://www.feepayr.com/';
 };
 const handlePayClick = () => {
  window.location.href = 'https://wbcap.in/';
};
const handleCancelClick=()=>{
  window.location.href = 'https://static.muoninfotech.com/./assets/resources/Step By Step Procedure to request for admission cancellation.pdf'

}

  const tabContents = [
    {
      title: 'Online Application',
      content: (
        <div>
         <h3>Online Application for Semester Wise 4/3 Year B.A./B.Sc./B.Com(Hons. with Research or without Research/ Multidisciplinary/Degree) Degree Programme</h3>
         <br></br>
         {/* <Button onClick={handleRegisterClick} className="register-button">Online Registration</Button>
         <Button onClick={handlePaymentClick} className="register-button">Online Payment </Button>
         <Button onClick={handleListClick} className="register-button">Admission Call List</Button>
         <Button onClick={handleInstructionClick} className="register-button">Application Instruction</Button>
         <Button onClick={handleAdmissionClick} className="register-button">Admission Schedule</Button>
         <Button onClick={handlePayClick} className="register-button">How to pay</Button>
         <Button onClick={handleCancelClick} className="register-button">Application Cancellation</Button> */}

           <Button onClick={handlePayClick} className="register-button">Centralised Admission Portal</Button>
        
         <br></br><br></br>
         <p style={{ color: 'green' }}><i><b>**Applicants for B.A/B.Sc./B.Com. Hons must apply for 4 Year Major.**Applicants for B.A./B.Sc./B.Com. 
         without Hons must  apply for 3 Year Multidisciplinary/Degree.  </b></i></p>
         <p><i><b>**For more details on courses see Courses offered tab on the left side</b></i></p>
         <p><i><b> </b></i></p>
         <p style={{ color: 'blue' }}><i><b>**Please see the notice board for updated Notice .  </b></i></p>
         {/* <Button onClick={handleNoticeClick} className="register-button"> Admission Call List Notice  </Button> <br></br> */}
         <p style={{ color: 'red' }}><i><b>**Applicants are requested to disregard any instruction for visiting the campus for physical submission of the application form. Your application will be considered complete once submitted through the online platform. </b></i></p>
         
        
         <ContactComponent/>
        </div>
      )
    },
    {
      title: 'Admission Call List',
      content: (
       
        <div>
        <h2>Coming Soon</h2>
        {/* <MeritList /> */}
        </div>
      )
    },
    {
      title: 'Provisional Merit List',
      content: (
        <div>
        <h2>Coming Soon</h2>
        {/* <i><b>Candidates are requested to check merit list Tab (on left side) for Online Admission List, 2023 </b> </i><br></br><br></br> */}
        {/* <Table  data={pdata} text={"Download"}  /> */}
        
        </div>
      )
    },
    {
      title: 'Courses Offered',
      content: (
        <div><SubjectComponent/></div>
      )
    },
    {
      title: 'Subject Combination',
      content: (
        <div>
          <h2>Subject Combination</h2>
          
          <Button ><Link to='https://static.muoninfotech.com/./assets/Admission Doc/BABSc 4 Years Subject Combination major.pdf'>B.A./B.Sc. 4 Years Subject Combination</Link></Button> <br></br>
          
          <Button><Link to='https://static.muoninfotech.com/./assets/Admission Doc/BABSc 3 Years Subject Combination general.pdf'>B.A./B.Sc. 3 Years Subject Combination</Link></Button><br></br>
          <Button><Link to='https://static.muoninfotech.com/./assets/B.com/B.Com Course Guideline.pdf'>B.Com. 4 & 3 Years Subject Combination</Link></Button>
        </div>
      )
    },
    {
      title: 'Course Eligibility',
      content: (
        <div>
          <><Eligibility/></>
        </div>
      )
    },
    {
      title: 'Seat Matrix',
      content: (
        <div>
          <SeatMatrix />
         
        </div>
      )
    },
    {
      title: 'Fees Structure',
      content: (
        <div>
          <Feestructure />
         
        </div>
      )
    },
    
    {
      title: 'How to apply',
      content: (
        <div>
          <h2>Application Procedure</h2>
         <Button><Link to='https://static.muoninfotech.com/./assets/Admission Doc/how to apply.pdf'>How to apply</Link></Button>
        </div>
      )
    },
    {
      title: 'Documents Required',
      content: (
        <div>
          <DocumentsRequired />
        </div>
      )
    },
    {
      title: 'Rules & Regulation',
      content: (
        <div>
          <h2>Rules & Regulation</h2>
          <br></br>
          <RulesAndRegulations/>
        </div>
      )
    }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const updateScreenSize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <>
    
      <div className={`vertical-tabs ${isMobile ? 'mobile-view' : ''}`}>
      {isMobile && (
        <>
          <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          {isMenuOpen && (
            <div className="menu-dropdown">
              {tabContents.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${index === activeTab ? 'mobactive' : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.title}
                </div>
              ))}
            </div>
          )}
        </>
      )}
     {!isMobile && (
        <div className="tab-list colorful-pattern">
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`tab ${index === activeTab ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      )}
      <div className="tab-content" ref={contentRef}>
        {tabContents[activeTab].content}
      </div>
    </div>
    </>
  );
};

export default AdmissionSidebar;