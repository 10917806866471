import React from 'react'
import CarouselComponent from '../components/CarouselComponent';
// import TableComponent from '../components/TableComponent';
import image1 from '../Image/iqac.png'
import Subheader from '../components/Subheader';
import Iqacsidebar from '../components/IQAC/Iqacsidebar'




function Iqac() {
    
    const heading=['Internal Quality Accredition Cell']
    const gbImages=[image1];
  return (
    <>
        <div>
        <CarouselComponent data={gbImages}/>
        <Subheader data={heading}/>
        </div>
        <div><Iqacsidebar/></div>
    </>
  )
}

export default Iqac