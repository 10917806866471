// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

// const Timetable = ({ data }) => {
//   const [viewPdf, setViewPdf] = useState(false);
//   const [pdfUrl, setPdfUrl] = useState('');
//   const [isMobileView, setIsMobileView] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobileView(window.innerWidth <= 600);
//     };

//     handleResize();

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const openPdf = (pdf) => {
//     setPdfUrl(pdf);
//     setViewPdf(true);
//   };

//   const closePdf = () => {
//     setViewPdf(false);
//     setPdfUrl('');
//   };

//   const renderTables = () => {
//     return data?.map((semester, index) => (
//       <div className="semester-table" key={index}>
//         <h2>{semester.semester}</h2>
//         {semester.pdf ? (
//           <>
//             {viewPdf && (
//               <div className="pdf-overlay">
//                 <div className="pdf-container">
//                   {isMobileView ? (
//                     <embed src={pdfUrl} type="application/pdf"  zoom="40%" />
//                   ) : (
//                     <embed src={pdfUrl} type="application/pdf" width="100%" height="500px" />
//                   )}
//                   <button onClick={closePdf}>Close</button>
//                 </div>
//               </div>
//             )}
//             <div className="pdf-link">
//               <a href="#" onClick={() => openPdf(semester.pdf)}>
//                 View
//               </a>
//             </div>
//           </>
//         ) : (
//           <table className="timetable">
//             <thead>
//               <tr>
//                 <th>Time</th>
//                 {renderHeaders(semester.days)}
//               </tr>
//             </thead>
//             <tbody>{renderRows(semester.slots, semester.days)}</tbody>
//           </table>
//         )}
//       </div>
//     ));
//   };

//   const renderHeaders = (days) => {
//     return days.map((day, index) => <th key={index}>{day}</th>);
//   };

//   const renderRows = (slots, days) => {
//     return slots.map((slot, index) => (
//       <tr key={index}>
//         <td>{slot.time}</td>
//         {days.map((day, index) => (
//           <td key={index}>
//             {slot[day] && (
//               <div>
//                 <p>{slot[day].subject}</p>
//                 <p>
//                   {slot[day].professor} | {slot[day].room}
//                 </p>
//               </div>
//             )}
//           </td>
//         ))}
//       </tr>
//     ));
//   };

//   return <div>{renderTables()}</div>;
// };

// Timetable.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       semester: PropTypes.string.isRequired,
//       days: PropTypes.arrayOf(PropTypes.string).isRequired,
//       slots: PropTypes.arrayOf(
//         PropTypes.shape({
//           time: PropTypes.string.isRequired,
//           Monday: PropTypes.shape({
//             subject: PropTypes.string.isRequired,
//             professor: PropTypes.string.isRequired,
//             room: PropTypes.string,
//           }),
//           Tuesday: PropTypes.shape({
//             subject: PropTypes.string.isRequired,
//             professor: PropTypes.string.isRequired,
//             room: PropTypes.string,
//           }),
//           Wednesday: PropTypes.shape({
//             subject: PropTypes.string.isRequired,
//             professor: PropTypes.string.isRequired,
//             room: PropTypes.string,
//           }),
//           Thursday: PropTypes.shape({
//             subject: PropTypes.string.isRequired,
//             professor: PropTypes.string.isRequired,
//             room: PropTypes.string,
//           }),
//           Friday: PropTypes.shape({
//             subject: PropTypes.string.isRequired,
//             professor: PropTypes.string.isRequired,
//             room: PropTypes.string,
//           }),
//         })
//       ).isRequired,
//       pdf: PropTypes.string, // Added the "pdf" prop type
//     })
//   ).isRequired,
// };

// export default Timetable;


import React from 'react'
import Table from "../../components/table"
import data from "../../resources/routine.json"

const Routine = ({dept}) => {
    const filteredData = data[dept];
  return (
    <div>
    {filteredData  && <Table data={filteredData} text={"Downlaod"}/>}
    
    
    </div>

  )
}

export default Routine;