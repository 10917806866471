import React from 'react'
import data from "../../resources/tplan.json"
import Table from "../../components/table"

const TeachingPlan = ({dept}) => {
    const filteredData = data[dept];
  return (
    <div>
    {filteredData && <Table data={filteredData} text={"view"}/>}
    
    
    </div>
  )
}

export default TeachingPlan