/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

const TextImage = ({data}) => {
  //console.log(data);
    const text = data.text;
    const imageUrls = data.imageUrls;
    return (
      <div className="sahara-container">
        <div
          className="sahara-text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <div className="sahara-images">
          {imageUrls.map((imageUrl, index) => (
            <div className="sahara-image" key={index}>
              <img src={imageUrl} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default TextImage;
  
