import React from 'react'
import Table from '../table'
import data from '../../resources/questions.json'

const Question = ({ dept }) => {
  const filteredData = data[dept];

  return (
    <div>
      {filteredData && <Table data={filteredData} text={"Download"} />}
    </div>
  )
}

export default Question