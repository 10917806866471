import React from 'react';

const Dheader = (data) => {
  return (
    <header className=" py-3" style={{backgroundColor:"rgb(246, 186, 111"}}>
      <div className="container-fluid text-white">
        <h1 className="m-0">DEPARTMENT OF {data.data?.toUpperCase()}</h1>
      </div>
    </header>
  );
};

export default Dheader;
