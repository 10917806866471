import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import principleImage from '../Image/principal.jpg'


const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <section className="about-us">
      <Container fluid>
        <Row>
          <Col>
            <h1 className="heading-text">Principal's Desk</h1>
            <div className="float-left"> <img  src={principleImage} alt='pics' /> </div>
            <div className='container'>
             <Row>
             <p style={{textAlign:'left'}}>
                Dear Students, Parents, Guardians and all our esteemed stakeholders</p>

                <p style={{textAlign:'left'}}>Calcutta Girls’ College is an institution where students are being nurtured,through its 
                journey of more than five decades, with liberal learning, scientific approach, environmental awareness, social 
                consciousness and women’s dignity and rights. We ensure a healthy ambience for the pursuit of higher education through 
                Grievance Redressal Cell, Internal Complaints Committee 
                and Anti-Ragging Cell.We keep them informed about all notifications through sms alerts.</p>

                <p style={{textAlign:'left'}}>As the Principal of an institution accredited by the NAAC in the 2nd cycle with B, I am happy to 
                state that we are engaged in a continuous review and upgradation of our academic and infrastructural 
                support to our students who are pursuing B.A., B.Com and B.Sc courses and P.G. courses under DODL, 
                Vidyasagar University. The college offers Virtual Class Room and Smart Class Room facilities, 
                a considerably rich library with e-facilities, a healthy Canteen. Students are initiated into 
                various aspects of higher education throughDeeksharambh. The college has an active NSS unit and 
                encourages several cocurricular activities. While Bharosa, our Women’s Cell organizes various 
                programmes on gender sensitization, Sahara is a unique attempt on our part to showcase the arts 
                and handicrafts of our students and offer sustained support towards training, upgradation and 
                marketing of the products. We also organize regular programmes forproviding platform for job 
                interface through Career Counselling I am certain that your association with Calcutta Girls’ 
                College will grow into an enriching bonding lasting a lifetime. I look forward to your views
                 and suggestions in our journey towards excellence.</p>

                <span ><p style={{textAlign:'right'}}>
                Dr. Satya Upadhyaya<br></br>
                Principal<br></br> </p>
              </span>
             </Row>
              
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
