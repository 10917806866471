import React from 'react'
import Table from '../components/table'
import data from '../resources/merit.json'

const Merit = () => {
  return (
    <Table data = {data} text = {"NA"}/>
  )
}

export default Merit