import HTMLFlipBook from "react-pageflip";
import React, { useState } from "react";

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <img src={props.imageSrc} alt="Cover" />
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <h1>CGC Magazine 2021</h1>
      <img src={props.imageSrc} alt="Page Image" />
      <p>{props.number}</p>
    </div>
  );
});

function MyAlbum(props) {
  const [inputText, setInputElement] = useState("");
  const [text, setText] = useState("It will be displayed here.");
  const printText = () => {
    setText(inputText);
    setInputElement("");
  };

  // Array of image paths
  const imagePaths = [
    "https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0001.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0002.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0003.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0004.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0005.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0006.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0007.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0008.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0009.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0010.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0011.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0012.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0013.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0014.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0015.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0016.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0017.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0018.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0019.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0020.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0021.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0022.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0023.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0024.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0025.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0026.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0027.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0028.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0029.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0030.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0031.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0032.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0033.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0034.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0035.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0036.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0037.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0038.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0039.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0040.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0041.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0042.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0043.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0044.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0045.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0046.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0047.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0048.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0049.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0050.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0051.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0052.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0053.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0054.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0055.jpg",
"https://static.muoninfotech.com/./assets/Magazine/CGC%20Magazine%20final_page-0056.jpg"
  ];

  return (
    <body bgcolor="LightCyan">
      <div>
        <HTMLFlipBook
          width={550}
          height={650}
          minWidth={315}
          maxWidth={1000}
          minHeight={420}
          maxHeight={1350}
          showCover={true}
          flippingTime={1000}
          style={{ margin: "0 auto" }}
          maxShadowOpacity={0.5}
          className="album-web"
        >
          {/* Render PageCover components dynamically */}
          {/* {imagePaths.map((imagePath, index) => (
            <PageCover key={index} imageSrc={imagePath} />
          ))} */}

          {/* Render Page components dynamically */}
          {imagePaths.map((imagePath, index) => (
            <Page key={index} number={index + 1} imageSrc={imagePath}>
              <hr></hr>
              <p contentEditable="true">This is Editable</p>
            </Page>
          ))}
        </HTMLFlipBook>
      </div>
    </body>
  );
}

export default MyAlbum;
