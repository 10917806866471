import React, {useState, useEffect} from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Envelope, Telephone, Map } from 'react-bootstrap-icons';
import status from '../resources/status.json';

const TopBar = () => {
  const isSunday = new Date().getDay() === 0;
  const openStatus = isSunday ? 'Closed' : status.status;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: 'rgb(246, 186, 111)',
    minHeight: '5px', // Adjust the height as needed
    '--bs-navbar-padding-y': '0',
    '--bs-navbar-color': 'rgb(0, 0, 0)',
    '--bs-navbar-disabled-color': 'rgb(0, 0, 0)',
    'marginTop': '-10px',
    //background: rgb(109, 169, 228); /* Fallback color */
    background: 'linear-gradient(to right, rgb(109, 169, 228), rgb(246, 186, 111))'
  };

  const iconStyle = {
    marginRight: '5px', // Adjust the spacing between icon and text
  };


  return (
    <Navbar style={navbarStyle} variant="dark">
      <Container>
        <Nav className="me-auto">
          {isMobile ? (
            <>
              <Nav.Link href="mailto:example@example.com">
                <Envelope style={iconStyle} size={20} />
              </Nav.Link>
              <Nav.Link href="tel:+1234567890">
                <Telephone style={iconStyle} size={20} />
              </Nav.Link>
              <Nav.Link href="https://www.google.com/maps">
                <Map style={iconStyle} size={20} />
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link href="mailto:example@example.com">
                <Envelope style={iconStyle} size={20} />
                collegecalcuttagirls@gmail.com
              </Nav.Link>
              <Nav.Link href="tel:+1234567890">
                <Telephone style={iconStyle} size={20} />
                033-22653414
              </Nav.Link>
              <Nav.Link href="https://www.google.com/maps">
                <Map style={iconStyle} size={20} />
                Campus
              </Nav.Link>
            </>
          )}
        </Nav>
        <Nav>
          <Nav.Link disabled>College is {openStatus} Today</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default TopBar;
