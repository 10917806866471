import React, { useState } from 'react';
import Table from '../table';


const MultiTableComponent = ({ data, headers, text, buttons }) => {
  const [selectedName, setSelectedName] = useState('All');

  // Filter data based on selected name
  const filteredData = selectedName === 'All' ? data : data.filter(item => item.name === selectedName);

  // Get unique names for dropdown options
  const uniqueNames = ['All', ...data.map(item => item.name)];

  const handleNameChange = event => {
    setSelectedName(event.target.value);
  };

  return (
    <div>
      <div className='filters' style={{ textAlign: 'center' }}>
        <label htmlFor="name-filter">Select a Name:</label>
        <select id="name-filter" value={selectedName} onChange={handleNameChange}>
          {uniqueNames.map(name => (
            <option key={name} value={name}>{name}</option>
          ))}
        </select>
      </div>

      {buttons && buttons.map((button, index) => {
        if (button.position === 'top') {
          return (
            <div key={index} className="button-container">
              <a href={button.URL} target="_blank" rel="noopener noreferrer" className="custom-button">{button.name}</a>
            </div>
          );
        }
        return null;
      })}

      {filteredData.length === 0 ? (
        <div className="image-container">
          <img src="https://static.muoninfotech.com/./assets/Coming soon png/coming soon.png" alt="Coming Soon" className="coming-soon-image" />
          <h2 className="unique-heading-classname">Currently Not Available.<br></br> Please check back after sometime</h2>
        </div>
      ) : (
        filteredData.map((item, index) => (
          <div key={index}>
            <h2>{item.name}</h2>
            {headers.map((header, headerIndex) => (
              <div key={headerIndex}>
                <h3>{header}</h3>
                <Table data={item[header.toLowerCase().replace(/\s/g, '')]} text={text} />
              </div>
            ))}
          </div>
        ))
      )}

      {buttons && buttons.map((button, index) => {
        if (button.position === 'bottom') {
          return (
            <div key={index} className="button-container">
              <a href={button.URL} target="_blank" rel="noopener noreferrer" className="custom-button">{button.name}</a>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default MultiTableComponent;
