import React, { useState, useRef, useEffect } from 'react';
import image1 from '../../Image/popup.png';
import '../homepage/Modal.css';
import { useNavigate } from 'react-router-dom';

const ModalComponent = () => {
  const popupRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeModal();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleapplybuttonclick = () => {
    // navigate('/admission');
    window.open('https://forms.gle/JD8ACUw4sqRVRBse8', '_blank');
    closeModal();
  }

  return (
    <div>
      {modalIsOpen && (
        <div className="lppopup" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="lppopup-content" ref={popupRef}>
            <div className="lpheader">
              <h5 className="modal-title">
                <b>Calcutta Girls College</b>
              </h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <img src='https://static.muoninfotech.com/./assets/Seminar/Modal_image_2024.png' alt='Apply for Online Admission 2023-24' className="img-fluid" />
            <div className="footer">
              <button className="btn btn-secondary" onClick={handleapplybuttonclick}>
                Register
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalComponent;
