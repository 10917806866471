import React from 'react'
import Table from '../table'
import data from "../../resources/deptactivity.json"

const Dactivity = ({dept}) => {
    const filteredData = data[dept];

  return (
    <div>
    {filteredData && <Table data={filteredData} text={"View"}/> }
    
    
    </div>
  )
}

export default Dactivity