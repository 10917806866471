import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutComponent = () => {
  return (
    
    <div className="container-lg">
    <br></br>
      <div className="row">
        <div className="col">
          <div className="section shadow">
          <br></br>
            <h2>About Us</h2><br></br>
            <p  style={{textAlign:'left' , lineHeight:'250%'}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              eu enim accumsan, lacinia velit sed, rhoncus libero. Sed
              vestibulum sollicitudin turpis. Donec auctor auctor ligula, in
              commodo erat hendrerit ac. Fusce facilisis lobortis nisi, sed
              lacinia nisi iaculis sit amet. Pellentesque id volutpat nibh, id
              ultricies lectus.
            </p><br></br>
            <p>
            <h2>IQAC Function</h2>
            <br></br>
            <ul style={{textAlign:'left' , lineHeight:'250%'}}>
              <li>Development and application of quality parameters for the various academic and administrative activities of the institution.</li>
              <li>Dissemination of information on the various quality parameters of higher education.</li>
              <li>Organization of workshops, seminars on quality related themes and promotion of quality circles.</li>
              <li>Documentation of the various programmes / activities leading to quality improvement.</li>
              <li>Preparation of the Self Study Report (SSR) Annual Quality Assurance Report (AQAR) to be submitted to NAAC based on the quality parameters.</li>
            </ul>
            </p>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="section shadow">
            <h2>IQAC Functions</h2>
            <ul>
              <li>Development and application of quality parameters for the various academic and administrative activities of the institution.</li>
              <li>Dissemination of information on the various quality parameters of higher education.</li>
              <li>Organization of workshops, seminars on quality related themes and promotion of quality circles.</li>
              <li>Documentation of the various programmes / activities leading to quality improvement.</li>
              <li>Preparation of the Self Study Report (SSR) Annual Quality Assurance Report (AQAR) to be submitted to NAAC based on the quality parameters.</li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AboutComponent;
