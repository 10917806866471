import React from 'react';

const AboutUs = ({data}) => {
  return (
    <div className="about-dept">
      <div className="aboutdeptcontent">
        <h2>INTRODUCTION</h2>
        <p dangerouslySetInnerHTML={{ __html: data }}></p>
      </div>
    </div>
  );
};

export default AboutUs;
