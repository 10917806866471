import React from 'react';
import {FcRight} from 'react-icons/fc'

const RulesAndRegulations = () => {
  const rules = [
    ' The entire admission process will be conducted in online mode. Candidates or guardians do not need to be physically present at the college until classes begin.',
    ' The online application form will be available from 1st July, 2023 (Saturday).',
    ' A candidate can apply for as many courses as she wishes under a single registration, subject to eligibility criteria.',
    ' Admission will be conducted in accordance with the directives from the University of Calcutta and the Department of Higher Education, Govt. of West Bengal.',
    ' The mobile number and email id provided in the form MUST belong to the candidate herself as further important communications will depend on this.',
    
  ];
   const rules2=[
    'All matters concerning Admission will be done ONLINE',
    'Aggregate means Total Marks of the Best FOUR Subjects (at least 100 marks paper)excluding Environmental Studies & Environmental Education in the qualifying exam.',
    'Admission through Merit List based on Index Marks',
    'Merit list is prepared based on Index Marks for all categories of candidates.',
    'Must have passed in English (with at least 100 Marks paper).',
    'Must have passed in the General (Elective) subjects applied for.'
   ]

  return (
    <div className="shadow p-3 mb-5 bg-body rounded">
      <div className="row">
        <div className='col'>
        
        {/* <div className="col-lg-15 offset-lg-15"> */}
          {/* <div className="sticky-middle">
        */}
            <ul >
              {rules.map((rule, index) => (
                <li key={index} className="list-group-item">
                  
                  <p style={{textAlign:'left'}}><FcRight/> {rule}</p>
                </li>
              ))}
            </ul>
          {/* </div> */}
        {/* </div> */}
        </div>

            <div className='col'> 
            
            {rules2.map((rule,index)=>{
                return (
                    <li key='index' className="list-group-item"> <p style={{textAlign:'left'}}><FcRight/> {rule}</p></li>
                )
            })}
            
            </div>


        
      </div>
    </div>
  );
};

export default RulesAndRegulations;
