import React from 'react';
import Table from '../components/table';
import data from '../resources/Cells.json';
import './trial.css';

const CustomComponent = ({ itemname }) => {
  const filteredData = data.filter(item => item.name === "SC/ST Cell");
  const primaryButtons = [];
  const secondaryButtons = [];

  filteredData.forEach((item, index) => {
    const { buttons } = item.data;
    buttons.forEach((button, buttonIndex) => {
      const buttonElement = (
        <button
          key={buttonIndex}
          onClick={() => window.open(button.onClick)}
          className={`button ${button.type}`}
        >
          {button.name}
        </button>
      );
      if (button.type === 'Primary') {
        primaryButtons.push(buttonElement);
      } else if (button.type === 'Secondary') {
        secondaryButtons.push(buttonElement);
      }
    });
  });

  return (
    <div>
      {filteredData.map((item, index) => (
        <div key={index} className='fullbody'>
          <h2>{item.name}</h2>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: item.data.description }}
          />
          <Table data={item.data.tablecontent} />
          <div>
            {primaryButtons.map((buttonElement) => buttonElement)}
          </div>
          {item.data.content.map((content, contentIndex) => (
            <div key={contentIndex}>
              <h3 className='headerul'>{content.heading}</h3>
              <div dangerouslySetInnerHTML={{ __html: content.intro }} />
              <ul className='cellsul'>
                {content.list.map((item, itemIndex) => (
                  <li key={itemIndex}>&#10004; {item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
      <div className="secondary-buttons-container">
        {secondaryButtons.map((buttonElement) => buttonElement)}
      </div>
    </div>
  );
};

export default CustomComponent;
