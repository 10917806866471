import React from 'react';
// import { Button } from 'react-bootstrap';
import './Impotantlinks.css'; // Import a CSS file for styling
// import { Link } from 'react-router-dom';


const SideButton = () => {
  return (
    <div className="side-button sticky">
      {/* <Link to='admission'><Button variant="primary" >Online Admission 2023</Button></Link> */}
   
    </div>
  );
};

export default SideButton;
