import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = () => {
  const [text, setText] = useState('');
  const [alignment, setAlignment] = useState('left');

  const handleTextChange = (value) => {
    setText(value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const htmlContent = `<html><body style="text-align: ${alignment};">${text}</body></html>`;

    // Create a Blob with the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });
    
    // Generate a download URL
    const url = URL.createObjectURL(blob);
    
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'rich_text.html';
    
    // Append the link to the DOM and trigger a click event
    document.body.appendChild(link);
    link.click();
    
    // Cleanup by removing the link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <ReactQuill value={text} onChange={handleTextChange} />
        <div>
          <label>
            Text Alignment:
            <select
              value={alignment}
              onChange={(e) => setAlignment(e.target.value)}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
              <option value="justify">Justify</option>
            </select>
          </label>
        </div>
        <button type="submit">Generate HTML</button>
      </form>
    </div>
  );
};

export default RichTextEditor;
