import React from 'react'
import TableComponet from '../../components/TableComponent'

const Iqacmembers = () => {

    const members=[ 
        { name: 'Dr.Satya Upadhyaya', designation: 'Principle'},
        { name: 'Janab Nadim-ul-Haque, Hon’ble M.P.', designation: 'President, G.B.'},
        { name: 'Mrs.Maitreyee Bhattacharya', designation: 'Member, G.B.'},
        { name: 'Sri.Sandipan Saha', designation: 'Councillor'},
        { name: 'Sri.Ramaprasad Bhattacharya', designation: ' Joint D.P.I'},
        { name: 'Dr.Suparna Bhattacharya', designation: 'Coordinator'},
        { name: 'Dr.Nayeem Ahmed', designation: 'Member'},
        { name: 'Dr.Debashis Biswas', designation: 'Member'},
        { name: 'Mrs.Kranti Dewan', designation: 'Member'}

    ]
  return (
    <div className='section-shadow'>
         <h2>Members of IQAC </h2><br></br>
        <i>The IQAC Sub-committee was formed on WEF 26.7.19 vide G.B. Resolution</i><br></br>
        <TableComponet tabledata={members}/>
    </div>
  )
}

export default Iqacmembers