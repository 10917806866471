import React, { useState } from "react";
import data from '../../resources/seatmatrix.json'
import './SeatMatrix.css'

const SeatMatrix = () => {
    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    const subjects = [...new Set(data.map((item) => item.Subject))];
    const categories = [
        "General",
        "SC",
        "ST",
        "OBC A",
        "OBC B",
        "Sports",
        "PWD (Total)",
        "PWD UR",
        "PWD ST",
        "PWD SC",
        "PWD OA",
        "PWD OB",
        "PWD EWS",
        "EWS",
        "Total"
    ];

    const filteredData = data.filter((item) => {
        if (selectedSubject && selectedCategory) {
            return item.Subject === selectedSubject && item[selectedCategory] > 0;
        } else if (selectedSubject) {
            return item.Subject === selectedSubject;
        } else if (selectedCategory) {
            return (
                categories.includes(selectedCategory) && item[selectedCategory] > 0
            );
        }
        return true;
    });

    const clearFilters = () => {
        setSelectedSubject("");
        setSelectedCategory("");
    };

    const noResultsMessage = (
        <tr>
            <td colSpan={categories.length + 1} className="no-results-message">
                <span className="emoji">😢</span>
                Sorry, we do not have any seats for the subject and category you selected.
                <button className="clear-button" onClick={clearFilters}>Click Here to see all options</button>
            </td>
        </tr>
    );

    return (
        <div className="seat-matrix">
            <div className="filters">
                <label htmlFor="subject-filter">Subject:</label>
                <select
                    id="subject-filter"
                    value={selectedSubject}
                    onChange={(e) => setSelectedSubject(e.target.value)}
                >
                    <option value="">All</option>
                    {subjects.map((subject) => (
                        <option key={subject} value={subject}>
                            {subject}
                        </option>
                    ))}
                </select>

                <label htmlFor="category-filter">Category:</label>
                <select
                    id="category-filter"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    <option value="">All</option>
                    {categories.map((category) => (
                        <option key={category} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            </div>

            <table className="seat-table">
                <thead>
                    <tr>
                        <th>Subject</th>
                        {categories.map((category) => (
                            <th key={category}>{category}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length === 0 ? (
                        noResultsMessage
                    ) : (
                        filteredData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Subject}</td>
                                {categories.map((category) => (
                                    <td
                                        key={category}
                                        className={
                                            selectedCategory === category && item[category] > 0
                                                ? "highlight"
                                                : ""
                                        }
                                    >
                                        {item[category]}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SeatMatrix;
