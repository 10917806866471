// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const TableComponent = (data) => {
//     //console.log(data)

//   return (
//     <div className="table-responsive">
//       <table className="table table-striped">
//         <thead>
//           <tr>
//             <th>Level of Study</th>
//             <th>Cut off Marks at Entry Level in %</th>
//             <th>Intake Capacity</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.data?.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, cellIndex) => (
//                 <td key={cellIndex}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default TableComponent;




import React from 'react';
import { ReactDOMServer } from 'react-dom/server';
import 'bootstrap/dist/css/bootstrap.min.css';

const createMarkup = (html) => {
  return { __html: html };
};

const TableComponent = (data) => {
  //console.log(data);

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Level of Study</th>
            <th>Cut off Marks at Entry Level in %</th>
            <th>Intake Capacity</th>
          </tr>
        </thead>
        <tbody>
          {data.data?.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>
                  <span dangerouslySetInnerHTML={createMarkup(cell)} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;

