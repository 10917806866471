import React, { useState, useRef } from 'react';
import './styling/NoticesSection.css';
import { useNavigate } from 'react-router-dom';
import { PDFExport } from '@progress/kendo-react-pdf';
import headImage from '../Image/header_cgc.jpg';
import footerImage from '../Image/footer_cgc.jpg';

const NoticeBoard = ({ data }) => {
   // Sort the data array in descending order based on the 'date' property
   const sortedData = data.map((box) => ({
    ...box,
    items: box.items.sort((a, b) => new Date(b.date) - new Date(a.date)),
  }));
  //console.log(data)
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/notice');
  };

  const closePopup = () => {
    setSelectedItem(null);
  };

  const pdfExportComponent = useRef(null);
  return (
    <div className="notice-board">
      {sortedData.map((box) => (
        <div key={box.id} className="notice-box">
          <h2>{box.title}</h2>
          <div className="scrollable-list-container"> {/* Added container */}
            {box.items.length > 0 ? (
              <div className="scrollable-list">
                {box.items.map((item) => (
                  <div key={item.id} className="list-item" onClick={() => handleClick(item)}>
                    <div className="date example-date">
                      <div className="day">{item.date.includes(',') ? item.date.substr(4, 2) : new Date(item.date).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }).substr(4, 2)}</div>
                      <div className="month">{item.date.includes(',') ? item.date.substr(0, 3) : new Date(item.date).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }).substr(0, 3)}</div>
                      <div className="year">{item.date.includes(',') ? item.date.substr(7) : new Date(item.date).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }).substr(7)}</div>
                    </div>
                    <div className="title">{item.title}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-notice">No Items to display as of Now. Please check back later.</div>
            )}
          </div>
          <button className="download-button" onClick={handleButtonClick}>
            All Notices
          </button>
        </div>
      ))}
      {selectedItem && (
        <div className="popup" style={{ display: 'flex', flexDirection: 'column' }}>
          <PDFExport ref={pdfExportComponent} paperSize="A4" fileName={`${selectedItem.title}_${selectedItem.date}`}>
            <div className="popup-content">
              <div className="header">
                <img src={headImage} alt="" width="100%" height="100%" />
              </div>
              {/* <h3>{selectedItem.title}</h3> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: "<p style='text-align: left;'>" + selectedItem.description.replace(/\n/g, '<br>') + '</p>',
                }}
              ></p>
              <p>Dated: {new Date(selectedItem.date).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}</p>
              {selectedItem.attachment && (
                <button
                  className="download-button"
                  onClick={() => window.open(selectedItem.attachment, '_blank')}
                >
                  Download Attachment
                </button>
              )}
              <div className="footer">
                <img src={footerImage} alt="" width="100%" height="100%" />
              </div>
            </div>
          </PDFExport>
          <div className="button-container">
            <button className="download-button" onClick={() => pdfExportComponent.current.save()}>
              Download PDF
            </button>
            <button className="close-button" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoticeBoard;