import React, { useState, useEffect } from 'react';
import {Nav as Navigation} from 'rsuite';
import  ResponsiveNav from '@rsuite/responsive-nav';
import 'rsuite/dist/rsuite.min.css';
import { Navbar, Nav as Navy, Container, NavDropdown } from 'react-bootstrap';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import menuItems from '../resources/menuitems.json'


function MenuItem({ item }) {

  if (item.subItems) {
    return (
      <NavDropdown title={item.title} id={`sub-menu-${item.title.replace(' ', '-')}`}>
      {item.subItems.map((subItem, subIndex) => (
        <MenuItem key={subIndex} item={subItem} />
      ))}
    </NavDropdown>
    );
  } else {
     const link = item.link.includes('{title}') ? `department/${item.title}` : item.link;
    return (
      <Navy.Link as={Link} to={link}>
        {item.title}
      </Navy.Link>
    );
  }
}

const renderNavItems = (items) => {
  return items.map((item) => {
    if (item.subItems) {
      return (
        <Navigation.Menu title={item.title} key={item.title} style={{ color: 'black', '--rs-navs-selected': '#fff'  }}>
          {renderNavItems(item.subItems)}
        </Navigation.Menu>
      );
    } else {
       const link = item.link.includes('{title}') ? `department/${item.title}` : item.link;
      return (
        <ResponsiveNav.Item key={item.title} style={{ color: 'black', '--bs-link-color': '#090909', '--rs-navs-selected': '#fff' }}>
          <Link to={link}>{item.title}</Link>
        </ResponsiveNav.Item>
      );
    }
  });
};

const renderMenuItems = () => {
  return menuItems.map((item, index) => (
    <MenuItem key={index} item={item} />
  ));
};

const App = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [background, setbackground] = useState('rgb(109, 169, 228)');
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setbackground('rgb(255, 255, 255)');
      }
    };

    // Set initial mobile state
    setIsMobile(window.innerWidth <= 768);

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <center>
          {isMobile ? (
            <Navbar
            expand="lg"
            style={{ backgroundColor: background, paddingTop: 0, paddingBottom: 0 }}
          >
           <Container>
           <Navbar.Toggle
             aria-controls="basic-navbar-nav"
             onClick={toggleMobileMenu}
             className={isMobileMenuOpen ? 'collapsed' : ''}
           >
             {isMobileMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
           </Navbar.Toggle>
           {!isMobile ? (
             <Navbar.Collapse
               id="basic-navbar-nav"
               className={`justify-content-center ${
                 isMobileMenuOpen ? 'show' : ''
               }`}
             >
               <Navy className="ml-auto">{renderMenuItems()}</Navy>
             </Navbar.Collapse>
           ) : null}
         </Container>
          {isMobileMenuOpen && (
            <div className="navbar-collapse">
              <div className="close-button" onClick={toggleMobileMenu}>
                <AiOutlineClose size={24} />
             </div>
             <Navy className="ml-auto">{renderMenuItems()}</Navy>
            </div>
          )}
        </Navbar>
          ) : (
            //Desktop Menu
            <div>
            <div style={{ background: 'rgb(109, 169, 228)' }}>
              <ResponsiveNav appearance="subtle">{renderNavItems(menuItems)}</ResponsiveNav>
            </div>
            </div>
            
          )}
          </center>
  );
};

export default App;
