import React from 'react';
import {MdTripOrigin} from 'react-icons/md'


const CodeofConduct = () => {
  return (
    <>
    <br />
    <div className="container">
      <h3>Code of conduct</h3>
      <br />
      <div className="row">
        <div className="col-md-12 text-start">
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Hand book of Code of
            Conduct
          </h4>
          <p>
            Calcutta Girls’ College considers values and ethics to be the basic
            edifice for the proper functioning of the institution and attainment of
            its objectives. The various stakeholders who constitute the institution
            are guided by a set of ideals and principles that are crucial in the
            overall progress and development of the institution.
          </p>
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Code of Conduct for
            Governing Body
          </h4>
          <p>
            The governing body of the college is the apex body invested with the
            responsibility of ensuring the effective management of the institution
            and for planning its future development. The functioning is guided by
            the following:
          </p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Approval of the mission and strategic vision of the institution,
              long-term academic plans to meet the interests of stakeholders.
            </li>
            <li>Monitoring institutional performance and quality assurance arrangements.</li>
            <li>
              Ensuring compliance with the statutes, ordinances and provisions regulating their
              institution, including regulations by Statutory bodies, such as UGC,
              as well as regulations laid out by the State government and affiliating university.
            </li>
            <li>Ensuring equal opportunity for all stakeholders</li>
            <li>Ensuring a transparent and smooth functioning of all aspects of the college</li>
          </ul>
          <br />
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Code of Conduct for Principal
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>Maintaining and promoting academic activities in the College</li>
            <li>Creating and maintaining an unbiased gender-free atmosphere</li>
            <li>
              Generating and maintaining required alertness among all the stakeholder of the College so
              that the chances of incidents of sexual harassment get ever minimized and ultimately eradicated
            </li>
            <li>
              Initiating and propagate the spirit of welfare within all the sections of human resources
              attached directly or indirectly with the College and hence to build mutual confidence amongst them.
            </li>
            <li>Ensuring a democratic and transparent functioning of various bodies within the college</li>
          </ul>
          <br />
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Code of Conduct for Teachers
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              The College considers the Code of Ethics recommended by the UGC as a
              guideline in framing its code for the teachers keeping in view the specific conditions of
              the institution.
            </li>
          </ul>
          <br />
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Professional Values
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Concern, Commitment and dedication to educating each student and taking
              care of specific needs and helping the student/s beyond class hours without accepting
              any remuneration.
            </li>
            <li>
              Developing an educational environment with no discrimination on the basis of caste,
              creed, religion, gender or socio-economic status
            </li>
            <li>Inspiring students to generate more interest and developing a sense of inquiry in the
              pursuit of knowledge.
            </li>
            <li>
              Instilling a scientific and democratic outlook among his students, making them community
              oriented, patriotic and broad minded.
            </li>
          </ul>
          <br />
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Professional Values
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>Continuously updating and upgrading of the self and the student community</li>
            <li>Pursuit of research and participation in seminars and workshops for professional development</li>
            <li>Developing new teaching strategies and curriculum as well as planning for an upgraded academic system</li>
            <li>
              Carrying out the Institution’s educational, extra-curricular responsibilities such as conducting admissions, college
              seminars and sports, extension activities and cultural programmes respectively
            </li>
          </ul>
          <br />
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Professional Collaboration
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Mutual respect and cooperation among colleagues, assisting them and sharing the responsibilities
              in a collaborative manner and also towards non-teaching staff
            </li>
            <li>
              Refraining from lodging unsubstantiated allegations against their colleagues
              in order to satisfy vested interests.
            </li>
            <li>Maintaining regular interactions with the guardians of the students</li>
          </ul>
          <h4>
            <MdTripOrigin color="rgb(98, 154, 231)" /> Code of Conduct for Non-Teaching Staff
          </h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Its obligatory for all Non-teaching staff to keep the mission and vision of the college in mind and
              ensure smooth functioning of the college with their punctuality
            </li>
            <li>
              Each of them should perform the duties he has been assigned sincerely and
              diligently as well as with accountability.
            </li>
            <li>It is expected that Non-teaching staff will offer assistance and support to the students</li>
            <li>Non-teaching staff are expected to bear the confidentiality of their task with honesty and dedication</li>
            <li>
              Non-teaching staff are a crucial part of college functioning and needs to be actively involved in decision
              making process.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
    
  );
};
export default CodeofConduct;
