import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import data from "../resources/AlumniRecord.json"

const ImageSlider = () => {
   
      return (
        <Carousel fade controls={false} interval={2000}>
          {data.map((image, index) => (
            <Carousel.Item key={index}>
              <Container className="carousel-container">
                <Row>
                  <Col xs={12} md={6} className="carousel-image">
                    <div className="image-wrapper">
                      <img src={image.url} alt={image.name} />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="carousel-details">
                    <h4>{image.name}</h4>
                    <p>{image.department}</p>
                    <p>{image.year}</p>
                    <p>{image.occupation}</p>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      );
    };

export default ImageSlider;
