import React from 'react'
import data from '../resources/feestructure.json'
import FeestructureComponent from '../components/Amission/MultiTableComponent'

function Feestructure() {
  const headerData = ["Tuition Fees", "Admission Fee"]
  return (
    <FeestructureComponent data = {data} headers={headerData}/>
  )
}

export default Feestructure