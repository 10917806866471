import React from "react";
import { Navigate } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Topbar from "./Topbar";
import NoticeEditor from "./noticeedit";
import "./admin.css";

function Dashboard() {
  const isAuthenticated = useStoreState((state) => state.user.isAuthenticated);

  // Check if the user is authenticated, otherwise redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard">
      <div className="dashboardtopbar">
        <Topbar />
      </div>
      <div className="main-content">
        <NoticeEditor />
      </div>
    </div>
  );
}

export default Dashboard;
