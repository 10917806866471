import React from 'react'
import Cells from '../components/Cells'
import data from '../resources/Cells.json'
import image1 from '../Image/STUDENTS ACTIVITIES.png'
import CarouselComponent from '../components/CarouselComponent'



const Antiraggingcell = () => {
   
  return (
   <>
   <CarouselComponent data={[image1]}/>
     <div><Cells data={data} itemname={"Anti Ragging Cell"}/><br></br>
    </div>
    <div> <p style={{textAlign :'justify' , marginLeft:'15%'}}><b>National Anti-Ragging Helpline</b></p>
    <p  style={{textAlign :'justify' , marginLeft:'15%'}}><b>Phone No</b> :- 18001805522</p>
    <p  style={{textAlign :'justify' , marginLeft:'15%'}}><b>Email</b> :- helpline@antiragging.in or cgc.antiragging@gmail.com</p>
    <p  style={{textAlign :'justify' , marginLeft:'15%'}}><b>Website:</b><a href='https://www.antiragging.in/'>  https://www.antiragging.in/</a></p>
    
    </div>
   </>
  )
}

export default Antiraggingcell


