import React, { useState, useEffect } from 'react';

const CounterComponent = () => {
  const [studentsCount, setStudentsCount] = useState(0);
  const [yearsCount, setYearsCount] = useState(0);
  const [alumniCount, setAlumniCount] = useState(0);

  const targetStudentsCount = 1200;
  const targetYearsCount = 50;
  const targetAlumniCount = 10000;

  useEffect(() => {
    const incrementCount = () => {
      if (studentsCount < targetStudentsCount) {
        setStudentsCount((prevCount) => prevCount + 1);
      }
      if (yearsCount < targetYearsCount) {
        setYearsCount((prevCount) => prevCount + 1);
      }
      if (alumniCount < targetAlumniCount) {
        setAlumniCount((prevCount) => prevCount + 1);
      }
    };

    const timer = setInterval(incrementCount, 100);

    return () => {
      clearInterval(timer);
    };
  }, [studentsCount, yearsCount, alumniCount]);

  return (
    <div className="counter-container">
      <div className="counter-item">
        <span className="counter-number">{studentsCount >= targetStudentsCount ? targetStudentsCount : studentsCount}+</span>
        <div className="counter-label">Students</div>
      </div>
      <div className="counter-item">
        <span className="counter-number">{yearsCount >= targetYearsCount ? targetYearsCount : yearsCount}+</span>
        <div className="counter-label">Years</div>
      </div>
      <div className="counter-item">
        <span className="counter-number">{alumniCount >= targetAlumniCount ? targetAlumniCount : alumniCount}+</span>
        <div className="counter-label">Strong Alumni</div>
      </div>
    </div>
  );
};

export default CounterComponent;
