import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ReadMoreComponent = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [close, setclose] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {isExpanded ? (
           <>
           <p  dangerouslySetInnerHTML={{__html: data}}></p>
            <Button variant='primary' onClick={toggleExpand}>Close</Button>
           </>
          ) : (
            <p>
              {data.slice(0, 200)}
              <span className="text-muted">...Read More</span>
            </p>
          )}
          {!isExpanded && (
            <>
            <Button variant='primary' onClick={toggleExpand}>
              Read More
            </Button>
            
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadMoreComponent;
